import axios from 'axios'
import qs from 'qs'
import { getAuthHeader } from 'utils/authHelper'
const baseUrl = '/api/images'

axios.interceptors.request.use(async function (config) {
	const header = await getAuthHeader()
	config.headers = header
	return config
})

/**
 * GET request to get all the images from /api/images
 * @returns {Array} Array of images
 */
const getAll = async () => {
	const res = await axios.get(baseUrl)
	return res.data
}


/**
 * GET request to get paginated images from /api/images
 * @param {Number} pageLimit, how many images we show on each page
 * @param {Boolean} nextPageBool, a boolean indicating if next was clicked or not (i.e next page = true , last page = false )
 * @param {String} startId, the first image id on the shown page
 * @param {String} endId, the last image id on the shown page
 * @returns {Array} Array of images
 * @returns {String} startId, The new starting image id for the fetched page
 * @returns {String} endId, The new ending image id for the fetched page
*/

const getPage = async (pageLimit, nextPageBool, startId, endId, searchCategory, searchTerms,langCode) => {
	const res = await axios.get(`${baseUrl}/page`, {
		params: {
			pageLimit: pageLimit,
			nextPageBool: nextPageBool,
			startId: startId,
			endId: endId,
			searchCategory: searchCategory,
			searchTerms: searchTerms,
			langCode: langCode
		}
	})
	return res.data
}

/**
 * Get an image by it's id
 * @param {String} id - GET request to get image that matches the id
 * @returns 
 */
const getById = async id => {
	const res = await axios.get(`${baseUrl}/id/${id}`)
	return res.data
}

/**
 * GET request to fetch all the images from the database that match one of our query terms
 * @param {Array} searchTerm - array of search term strings
 * @returns {Array} of image objects that match one of the search terms
 */
const getBySearchTerm = async (searchTerm) => {
	const res = await axios.get(`${baseUrl}/search`, {
		params: {
			tags: searchTerm,
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	})
	return res.data
}

const update = async (id, changes, userId) => {
	const res = await axios.put(`${baseUrl}/id/${id}`, {changes, userId})
	return res.data
}

/**
 * POST request to add a new image to database and returns a data of a response from the server
 * @param {FormData} newImage - FormData object containing text and image content of the new image
 * @returns {object} data with image details from the server
 */
const create = async (newImage) => {
	const res = await axios.post(baseUrl, newImage)
	return res.data
}

/**
 * DELETE request to delete an image from the database
 * @param {String} id - Image id as String
 * @returns {String} id of deleted image
 */
const deleteById = async (id) => {
	const res = await axios.delete(`${baseUrl}/id/${id}`)
	return res.data
}

export default { getAll, getPage, getById, getBySearchTerm, update, create, deleteById }