import { useTranslation } from 'react-i18next'
import { Button, Popover, OverlayTrigger } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'

const TextPrompt = () => {
	const { t } = useTranslation()
	const hint = useSelector(state => state.currentExercise.currentQuestion.hint)
	const currentAudioBuffer = useSelector(state => state.currentExercise.currentAudioBuffer)

	const popover = (
		<Popover data-cy='exercise_hint_popover'>
			<Popover.Header><h3>{t('exercise.hint.popover_title')}</h3></Popover.Header>
			<Popover.Body>
				<h4>
					{currentAudioBuffer.hint.length > 0
						? <span className='px-2'>
							<AudioPlayer
								buffer={currentAudioBuffer.hint}
								text={false}
							/>
						</span>
						: <></>
					}
					{hint}
				</h4>
			</Popover.Body>
		</Popover>
	)

	return (
		<>
			<OverlayTrigger trigger="click" placement='top' overlay={popover} rootClose>
				<Button
					className='fs-4 exercise--secondary-button exercise--header-button'
					variant='outline-primary' data-cy='exercise_hint_button'
				>
					<FontAwesomeIcon icon={faLightbulb} />
				</Button>
			</OverlayTrigger>
			<h5 className='mt-1'>{t('exercise.hint.subtitle')}</h5>
		</>
	)
}

export default TextPrompt