import { Form, Accordion, Row, Col, OverlayTrigger } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { changeTitle, changeSlpDescription, changePatientDescription } from 'reducers/newOrEditedExerciseSlice'


const CreateExerciseDesc = ({ renderPopover }) => {
	const { t } = useTranslation()
	const title = useSelector(state => state.newOrEditedExercise.exercise.title.text)
	const description = useSelector(state => state.newOrEditedExercise.exercise.description)
	const dispatch = useDispatch()
	const [borderColor, setBorderColor] = useState('')

	/**
     * Responsible for updating border color of the Accordion depending on if information is filled in
     */
	useEffect(() => {
		const hasTitle = title !== ''
		const hasDescription = description.slp.text.length > 0 && description.patient.text.length > 0
		if (!(hasTitle && hasDescription)) setBorderColor('red')
		else setBorderColor('')
	}, [title, description])

	/**
     * Responsible for handling onchange event of exercise title
     * @param {Event} event - event instance of the select element
     * @returns {Void}
     */
	const handleChange = event => {
		event.preventDefault()
		const name = event.target.name
		if (name === 'title') {
			dispatch(changeTitle(event.target.value))
		} else if (name === 'slpDesc') {
			dispatch(changeSlpDescription(event.target.value))
		} else if (name === 'patientDesc') {
			dispatch(changePatientDescription(event.target.value))
		}
	}

	return (
		<Accordion className='mt-3 mb-3'>
			<Accordion.Item style={{borderColor: borderColor}}>
				<Accordion.Header>
					<Form.Label className="fw-bold">{t('create_exercise.general.title')}</Form.Label>
				</Accordion.Header>
				<Accordion.Body>
					<Form.Group className="mb-3" controlId="formTitle">
						<Form.Label className="fw-bold">{t('create_exercise.general.exercise_title')}</Form.Label>
						<OverlayTrigger trigger="focus" placement="bottom" overlay={renderPopover(t('create_exercise.instructions.title'))}>
							<Form.Control data-cy="exercise_title" type="text" name="title" onChange={handleChange} value={title} placeholder="" required minLength='1' maxLength='200' />
						</OverlayTrigger>
					</Form.Group>
					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="slpDescription">
								<Form.Label className="fw-bold">{t('create_exercise.general.slp_description')}</Form.Label>
								<OverlayTrigger trigger="focus" placement="bottom" overlay={renderPopover(t('create_exercise.instructions.description_slp'))}>
									<Form.Control data-cy="exercise_description_slp" as="textarea" rows={3} name="slpDesc" onChange={handleChange} value={description.slp.text} placeholder="" required minLength='1' />
								</OverlayTrigger>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId="patientDescription">
								<Form.Label className="fw-bold">{t('create_exercise.general.patient_description')}</Form.Label>
								<OverlayTrigger trigger="focus" placement="bottom" overlay={renderPopover(t('create_exercise.instructions.description_patient'))}>
									<Form.Control data-cy="exercise_description_patient" as="textarea" rows={3} name="patientDesc" onChange={handleChange} value={description.patient.text} placeholder="" required minLength='1' />
								</OverlayTrigger>
							</Form.Group>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default CreateExerciseDesc