import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import ExercisePromptDefault from 'components/ExercisePrompt/ExercisePromptDefault'
import ExercisePromptFill from 'components/ExercisePrompt/ExercisePromptFill'
import ExercisePromptListen from 'components/ExercisePrompt/ExercisePromptListen'
import ExercisePromptRead from 'components/ExercisePrompt/ExercisePromptRead'
import './ExercisePrompt.css'

import { EXERCISE_ANSWERFORMAT_DEFAULT, EXERCISE_ANSWERFORMAT_FILL, EXERCISE_TYPE_LISTEN, EXERCISE_TYPE_READ } from 'utils/config'

const ExercisePrompt = () => {

	const question = useAppSelector(state => state.currentExercise.currentQuestion)
	const exercise = useAppSelector(state => state.currentExercise.exercise)

	/**
     * Returns the text prompt layout depending on exercise type
     */
	const textPrompt = () => {
		if (question.template == EXERCISE_ANSWERFORMAT_DEFAULT) {
            return (
                <ExercisePromptDefault />
            )
		} else if (question.template == EXERCISE_ANSWERFORMAT_FILL) {
            return (
                <ExercisePromptFill />
            )
		} else if (exercise.answerFormat == EXERCISE_TYPE_LISTEN) {
            return (
                <ExercisePromptListen />
            )
		} else if (exercise.answerFormat == EXERCISE_TYPE_READ) {
            return (
                <ExercisePromptRead />
            )
		}
	}

	return (
		<>
			{textPrompt()}
		</>
	)
}

export default ExercisePrompt