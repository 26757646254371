import React, { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

interface Props {
    title: string
    text: string
    link: string
}
const DashboardCard = ({ title, text, link }: Props) => {
	const navigate = useNavigate()

	const goToPage = () => {
		navigate(link)
	}

	return (
		<Card
			className='m-2 border-1'
			style={{cursor: 'pointer', maxHeight: '12rem'}}
			onClick={goToPage}
			data-cy='dashboard-card'
		>
			<Card.Header as='h2'>
				{title}
			</Card.Header>
			<Card.Body>
				<Card.Text>
					{text}
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default DashboardCard