import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { getExerciseList } from 'reducers/exerciseListSlice'
import { getLanguageCode } from 'utils/helpers'

const LanguageSwitcher = ({ getExercises=true }) => {
	const { i18n } = useTranslation()
	const user = useSelector(state => state.user)
	const dispatch = useDispatch()

	const handleLangChange = e => {
		e.preventDefault()
		const lang = e.target.value
		i18n.changeLanguage(lang)
		const langCode = getLanguageCode(lang)

		if (getExercises) {
			dispatch(getExerciseList({user, langCode}))
		}

	}
	return (
		<>
			<Form.Select data-cy={'app_lang_select_' + getExercises} className="menu--language-select" style={{maxWidth: '150px'}} onChange={handleLangChange} value={i18n.language}>
				<option value="sv">Svenska</option>
				<option value="en">English</option>
				<option value="fi">Suomi</option>
			</Form.Select>
		</>
	)
}

export default LanguageSwitcher