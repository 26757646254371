import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Modal, Alert } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import exerciseService from 'services/exercises'
import {
	reset,
	changeExerciseMessage,
	resetExerciseMessage,
	changeExerciseMessageVariant
} from 'reducers/newOrEditedExerciseSlice'

interface Props {
    handleClose:() => void
	handleDelete:() => void
}

const ConfirmDeleteExerciseModal = ({ handleClose, handleDelete }: Props) => {
	const { t } = useTranslation()
	const userId = useAppSelector(state => state.user.data.id)
	const exercise = useAppSelector(state => state.newOrEditedExercise.exercise)
	const dispatch = useDispatch()


	const deleteExercise = async () => {
		handleDelete()
		try {
			const res = await exerciseService.deleteById(exercise.id, userId)
			if (res) {
				dispatch(changeExerciseMessage(`${t('preview_exercise.delete.success', {title: exercise.title.text})}`))
			}
			dispatch(reset())
			setTimeout(() => {
				dispatch(resetExerciseMessage())
			}, 10000)
		} catch (err) {
			console.error('Error deleting exercise', err)
			dispatch(changeExerciseMessage(`${t('create_exercise.messages.error')} ${err.message}`))
			dispatch(changeExerciseMessageVariant('danger'))
			setTimeout(() => {
				dispatch(resetExerciseMessage())
			}, 10000)
		}
	}

	return (

		<>
			<Modal show={true} data-cy='delete_exercise_modal'>
				<Modal.Header closeButton onClick={handleClose}>
					<Modal.Title data-cy='delete_exercise_modal_title'>
						{t('preview_exercise.delete.confirm_title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p data-cy='delete_exercise_modal_text'>{t('preview_exercise.delete.confirm_message')}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} variant="outline-dark" data-cy='cancel_delete_exercise_button'>
						{t('preview_exercise.delete.cancel')}
					</Button>
					<Button onClick={deleteExercise} variant="danger" data-cy='delete_exercise_button'>
						{t('preview_exercise.delete.confirm_button')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmDeleteExerciseModal