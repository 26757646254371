import React from 'react'
import { useTranslation } from "react-i18next"
import { Col, Card } from 'react-bootstrap'
import AnswerTags from 'components/Answers/AnswerTags'

const Answers = () => {    
    const { t } = useTranslation()

    return (
        <Col className='align-self-center'>
            <Card className='mb-2 mt-2'>
                <Card.Header className='exercise--answer-header'>
                    <Card.Title>
                        {t("exercise.answers_header")}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                <AnswerTags />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default Answers