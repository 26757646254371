import React from 'react'
import { Answer } from 'types/AnswerTypes'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import { useAppSelector } from 'stateHandling/hooks'
import { getConfidenceBgColor, getConfidenceColor } from 'utils/helpers'
import { EXERCISE_TYPE_LISTEN } from 'utils/config'

interface Props {
    answerObj: Answer
	confidence: number
}

const AnswerAlternativeText = ({answerObj, confidence }: Props) => {
	const exercise = useAppSelector(state => state.currentExercise.exercise)

	return (
		<div className='exercise--feedback-writtenAnswer p-2 text-start'
			style={{backgroundColor: getConfidenceBgColor(confidence), borderColor: getConfidenceColor(confidence)}}
		>
			{ exercise.answerFormat === EXERCISE_TYPE_LISTEN
				? <>
					<AudioPlayer
						buffer={answerObj.audio} // Will fail, no audio Buffer fetched yet
						large={false}
						text={false}
					/>
				</>
				: <></>
			}
			<span className='mx-2 align-middle'>{answerObj.answer.text}</span>
	</div>
	)
}

export default AnswerAlternativeText