import React, {ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import LanguageIcon from '@mui/icons-material/Language'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EqualizerIcon from '@mui/icons-material/Equalizer'

import { useAppSelector } from 'stateHandling/hooks'
import { tagColor, filterIconColor, getAnswerFormat, getExerciseSelectAttribute } from 'utils/helpers'
import ExpandableText from 'components/ExerciseLibraryCard//ExpandableText'

import { LibraryExercise } from 'types/ExerciseTypes'
import { AdminUser } from 'types/UserTypes'

import dialogLogo from 'dialog_logo_inverted_text_purple.png'
import './ExerciseLibraryCard.css'

interface Props {
    exercise: LibraryExercise,
    idx: string
	baseLib: boolean
	viewExercise: (id: string, renderType: string, baseLib:boolean) => any
	startExercise: (id: string, renderType: string) => any
	getIcon: (answerFormat: string) => IconProp
	assign: boolean
	selectedExercises: LibraryExercise[]
	exerciseSelect: (event: ChangeEvent<HTMLInputElement>, exercise: LibraryExercise, renderType: string) => void
	showPlay: boolean
	toggleShowStatistics: (exerciseId: string) => void
}

const ExerciseLibraryCard = (
	{ exercise, idx, baseLib, viewExercise, startExercise, getIcon, assign, selectedExercises, exerciseSelect, showPlay,toggleShowStatistics }
: Props) => {
	const { t } = useTranslation()
	const user = useAppSelector(state => state.user)

	/*
	Detect if the exercise is created by the current user
	Set the state-variable that controls the card header
	*/
	const ownExercise = (id: string) => {
		const adminUser = user.data as AdminUser
		return adminUser.exercises.includes(id)
	}

	const checkExerciseSelection = (exercise: LibraryExercise) => {
		const matchingExercise = selectedExercises.filter(e => e.id === exercise.id)[0]
		return matchingExercise ? matchingExercise[getExerciseSelectAttribute[exercise.renderType]] : false
	}

	/**
	 * Returns the card header
	 * @returns { JSX.Element }
	 */
	const getCardHeader = () => {
		if (exercise.baselib ? exercise.baselib : baseLib) {
			return (
				<div className='exerciseLibraryCard--base-library-header'
					data-cy='exercise_library_card_header_baselib'
				>
					<img
						src={dialogLogo}
						className='px-3 exerciseLibraryCard--header-icon'
						alt="Dialog logo"
					/>
				</div>
			)
		} else if (ownExercise(exercise.id)) {
			return (
				<div className='exerciseLibraryCard--own-exercise-header'
					data-cy='exercise_library_card_header_own'
				>
					<span>
						<PersonIcon className='mx-2 table-svg-icons'/>
						{t('exercise_library_card.card_header.my_exercise')}
					</span>
				</div>
			)
		}
		return (
			<div className='exerciseLibraryCard--community-exercise-header'
				data-cy='exercise_library_card_header_community'
			>
				<span>
					<LanguageIcon className='mx-2 table-svg-icons'/>
					{t('exercise_library_card.card_header.community')}
				</span>
			</div>
		)
	}

	return (
		<Card id={exercise.template + '_' + exercise.renderType + '_' + idx} data-cy='exercise_library_card'>
			<Card.Body className="exerciseLibraryCard--card-body">
				{getCardHeader()}
				<div className='p-3'>
					<Card.Title data-cy='exercise_library_card_title'>
						<Row xs='auto' className='align-items-center justify-content-center'>
							<Col className='flex-grow-1'
								xs={!exercise.settings.isPublic ? 6 : 8}
								sm={!exercise.settings.isPublic ? 8 : 10}
							>
								<div className="d-inline exerciseLibraryCard--title-icon">
									<FontAwesomeIcon
										style={{ color: filterIconColor[exercise.renderType] }}
										icon={getIcon(exercise.renderType)}
									/>
								</div>
								{exercise.title.text}
							</Col>
							{!exercise.settings.isPublic
								? <Col className='float-end text-end align-self-center'>
									<LockIcon className='m-1 exerciseLibraryCard--private-icon'
										data-cy='private_exercise_icon'
									/>
								</Col>
								: <></>
							}
							{assign
								? <Col className='float-end text-end'>
									<input
										onChange={(event) => exerciseSelect(event, exercise, exercise.renderType)}
										checked={checkExerciseSelection(exercise)}
										type='checkbox'
										data-cy={`exercise_select_${exercise.id}`}
										className='exerciseLibraryCard--exercise-select'
									/>
								</Col>
								: <></>
							}

						</Row>
					</Card.Title>
					{exercise.description!.slp.text.length > 0
						? <ExpandableText text={exercise.description!.slp.text} />
						: <></>
					}
					<Row xs='auto'>
						<Col className='exerciseLibraryCard--small-font' data-cy='exercise_library_card_template'>
							<b>{t(`exercise_card.format.${exercise.template}`)}</b>
						</Col>
						<Col className='flex-grow-1 exerciseLibraryCard--small-font' data-cy='exercise_library_card_answerformat'>
							<b>{t(`exercise_card.alternative_format.${getAnswerFormat(exercise, exercise.renderType)}`)}</b>
						</Col>
						<Col className='exerciseLibraryCard--small-font'>
							<b>{t(`create_exercise.settings.category.${exercise.category}`)}</b>
						</Col>
					</Row>
					<Card.Text className='mb-0 exerciseLibraryCard--small-font'
						data-cy='exercise_library_card_num_questions'
					>
						{t('exercise_card.num_questions', {numQuestions: exercise.questions.length})}
						<br />
					</Card.Text>
					<Row xs='auto' className='align-items-center'>
						<Col className='flex-grow-1'>
							<Row xs="auto">
								{exercise.subtype.map(type => {
									return (
										<Col key={type} className='m-2 exerciseLibraryCard--tag text-nowrap'
											style={{
												color: tagColor[type] ? tagColor[type].color : tagColor.default.color,
												backgroundColor: tagColor[type]
													? tagColor[type].bgColor : tagColor.default.bgColor,
												borderColor: tagColor[type]
													? tagColor[type].borderColor : tagColor.default.borderColor
											}}
											data-cy='exercise_library_card_tag'
										>
											{t(`exercise_card.subtypes.${type}`)}
										</Col>
									)})
								}
							</Row>
						</Col>
						<Col>
							<OverlayTrigger
								key={`preview-tooltip-${exercise.id}`}
								trigger={['hover', 'focus']}
								placement='top'
								overlay={
									<Tooltip id={`preview-tooltip-${exercise.id}`}>
										{t('exercise_library_card.preview_exercise_tooltip')}
									</Tooltip>
								}>
								<FactCheckIcon
									onClick={viewExercise(exercise.id, exercise.renderType, baseLib)}
									className='exerciseLibraryCard--icon-button'
									data-cy='exercise_library_card_preview_button'
								/>
							</OverlayTrigger>
						</Col>
						{showPlay
							? <Col>
								<OverlayTrigger
									key={`start-tooltip-${exercise.id}`}
									trigger={['hover', 'focus']}
									placement='top'
									overlay={
										<Tooltip id={`start-tooltip-${exercise.id}`}>
											{t('exercise_library_card.start_exercise_tooltip')}
										</Tooltip>
									}>
									<PlayArrowRoundedIcon
										onClick={() => startExercise(exercise.id, exercise.renderType)}
										className='exerciseLibraryCard--icon-button'
										data-cy='exercise_library_card_play_button'
									/>
								</OverlayTrigger>
							</Col>
							:<></>
						}
						{assign
							? <td className='exercise-library--table-row-action-col'>
								<OverlayTrigger
									key={`start-tooltip-${exercise.id}`}
									trigger={['hover', 'focus']}
									placement='top'
									overlay={
										<Tooltip id={`start-tooltip-${exercise.id}`}>
											{t('exercise_library_card.show_statistics_tooltip')}
										</Tooltip>
									}>
									<EqualizerIcon
										className='exerciseLibraryCard--icon-button'
										onClick={() => toggleShowStatistics(exercise.id)}
										data-cy='exercise_library_card_statistics_button'
									/>
								</OverlayTrigger>
							</td>
							:<></>
						}
					</Row>
				</div>
			</Card.Body>
		</Card>
	)
}

export default ExerciseLibraryCard