import { Button, Container } from 'react-bootstrap'
import { useTranslation } from "react-i18next"


/**
 * A view that can be used as default when an error occurs within the application
 * Currently used as a wrapper around everything in App.js
 * About wrapping FE error handler in the App.js component:
 * https://stackoverflow.com/questions/64127446/wrap-entire-app-in-single-errorboundary-react-error-boundary
 * Library and examples: https://github.com/bvaughn/react-error-boundary
*/
const ErrorFallback = ({error, resetErrorBoundary}) => {
    const { t } = useTranslation()  
    return (
        <Container>
			<h1>Oops!</h1>
			<p>{t("fe_error.info_text")}</p>
			<pre>{error.message}</pre>
			<Button variant="primary" onClick={resetErrorBoundary}>{t("fe_error.try_again")}</Button>
        </Container>
      )
}

export default ErrorFallback