import { Col, Row, Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Recorder from 'components/Recorder'
import Write from 'components/Write'
import TextPrompt from 'components/TextPrompt'

const StatusNone = ({ start, stop, submit, recorderAlert, backendError }) => {
	const { t } = useTranslation()
	const format = useSelector(state => state.currentExercise.exercise.answerFormat)
	const showHint = useSelector(state => state.currentExercise.exercise.settings.useHint)

	return (
		<>
			{ format === 'speak'
				? <Row className="exercise--footer-fixed justify-content-center align-items-center">
					{recorderAlert
						? <Col xs={12} sm={10} md={8} lg={6} xl={4}>
							<Alert variant='danger'>
								<h4>{t('exercise.alert.recorder_error_title')}</h4>
								<p>{t('exercise.alert.recorder_error_text')}</p>
							</Alert>
						</Col>
						: <>
							{backendError
								? <Row className="justify-content-center">
									<Col xs={12} sm={10} md={8} lg={6} xl={4}>
										<Alert variant='danger'>
											<h4>{t('exercise.alert.backend_error_title')}</h4>
											<p>{t('exercise.alert.backend_error_text')}</p>
										</Alert>
									</Col>
								</Row>
								: <></>
							}
							<Col className='text-start' xs={3} sm={3} md={3} lg={3} xl={3}>
								{ showHint ? <TextPrompt /> : <></> }
							</Col>
							<Col className='text-center' xs={4} sm={4} md={4} lg={4} xl={4}>
								<Recorder
									start={start}
									stop={stop}
								/>
							</Col>
							<Col className='text-center' xs={3} sm={3} md={3} lg={3} xl={3}>
							</Col>
						</>
					}
				</Row>
				: <>
					<Row className="exercise--footer-fixed justify-content-center align-items-center">
						<Col className='text-center' xs={2} sm={2} md={2} lg={3} xl={3}>
							{ showHint ? <TextPrompt /> : <></> }
						</Col>
						<Col className='text-center' xs={8} sm={6} md={6} lg={4} xl={4}>
							<Write
								submit={submit}
							/>
						</Col>
						<Col className='text-center' xs={2} sm={2} md={2} lg={3} xl={3}>
						</Col>
					</Row>
				</>
			}
		</>
	)
}

export default StatusNone