import { useTranslation } from 'react-i18next'
import userService from 'services/users'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, Container, Button, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'

const UserAdminPage = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const user = useSelector((state) => state.user)
	const [allUsers, setAllUsers] = useState([])
	const [slpOrAdmin, setSlpOrAdmin] = useState([])
	const [patients, setPatients] = useState([])

	const fetchData = async (user) => {
		const userType = user.data.type
		try {
			const allUsers = await userService.getAllUsers(userType)
			setAllUsers(allUsers)
		} catch (exception) {
			console.error(exception)
		}
	}

	useEffect(() => {
		fetchData(user)
	}, [user])

	useEffect(() => {
		separateData()
	}, [allUsers])

	const separateData = () => {
		const filteredPatients = allUsers.filter(user => user.type === 'user')
		setPatients(filteredPatients)

		const filteredSlpOrAdmin = allUsers.filter(user => user.type !== 'user')
		setSlpOrAdmin(filteredSlpOrAdmin)
	}

	const idToName = (id) => {
		const matchingUser = slpOrAdmin.find(user => user.id === id)
		return matchingUser ? matchingUser.username : null
	}

	const showCreateUserPage = () => {
		navigate('/create_user')
	}

	return (
		<Container>
			<h1>{t('user_admin.page_title')}</h1>
			<Button data-cy='user_admin_create_button' className='mb-2' onClick={showCreateUserPage}>
				<FontAwesomeIcon icon={faAdd}/>
				<span className='mx-2'>{t('user_admin.add_new_button')}</span>
			</Button>
			<Table className='user-select' striped hover responsive data-cy='user_admin_admin_table'>
				<thead>
					<tr>
						<th>{t('user_admin.table.type')}</th>
						<th>{t('user_admin.table.username')}</th>
						<th>{t('user_admin.table.email')}</th>
						<th>{t('user_admin.table.organisation')}</th>
						<th>{t('user_admin.table.patients')}</th>
						<th>{t('user_admin.table.user_status')}</th>
					</tr>
				</thead>

				<tbody>
					{slpOrAdmin.map((user, idx) =>
						<tr key={idx}>
							<td>{t(`user_admin.table.type_${user.type}`)}</td>
							<td>{user.username}</td>
							<td>{user.email}</td>
							<td>{user.organisation}</td>
							<td>{user.patients.length}</td>
							<td>
								<Badge className='mx-2 px-2' data-cy="confirmation_badge"
									bg={user.userStatus === 'CONFIRMED' ? 'success' : 'secondary'}>
									{t(`edit_patient.info.${user.userStatus}`)}
								</Badge>
							</td>
						</tr>)}
				</tbody>

			</Table>

			<Table className='user-select' striped hover responsive data-cy='user_admin_patient_table'>
				<thead>
					<tr>
						<th>{t('user_admin.table.type')}</th>
						<th>{t('user_admin.table.username')}</th>
						<th>{t('user_admin.table.parent_account')}</th>
						<th>{t('user_admin.table.exercises')}</th>
						<th>{t('user_admin.table.user_status')}</th>
					</tr>
				</thead>

				<tbody>
					{patients.map((user, idx) =>
						<tr key={idx}>
							<td>{t(`user_admin.table.type_${user.type}`)}</td>
							<td>{user.username}</td>
							<td>{idToName(user.parentAccount)}</td>
							<td>{user.exercises.length}</td>
							<td>
								<Badge className='mx-2 px-2' data-cy="confirmation_badge"
									bg={user.userStatus === 'CONFIRMED' ? 'success' : 'secondary'}>
									{t(`edit_patient.info.${user.userStatus}`)}
								</Badge>
							</td>
						</tr>)}
				</tbody>

			</Table>
		</Container>
	)
}

export default UserAdminPage
