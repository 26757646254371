import { Card } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import React from 'react'

interface Props {
	duration: number
  }

const Feedback = ({ duration }: Props) => {

	const scenarioTitle = useAppSelector(state => state.chatbot.currentScenario.title)
	const turn = useAppSelector(state => state.chatbot.scenarioSettings.currentTurn)

	return (
		<div className='chatbot--feedback-container d-flex flex-column align-items-center justify-content-center'>
			<div className='feedback-text text-center m-4'>
				<h1>Bra jobbat! 🎉</h1>
				<h1>Du har avslutat konversationen {scenarioTitle}</h1>
			</div>
			<Card className='w-50'>
				<Card.Header className='chatbot--card-header'>Sammanfattning</Card.Header>
				<Card.Body>
					<Card.Title className='chatbot--card-title'>
						<FontAwesomeIcon icon={faClock} /> {duration}
					</Card.Title>
					<Card.Title className='chatbot--card-title'>
						<FontAwesomeIcon icon={faComment} /> {turn} svar
					</Card.Title>
				</Card.Body>
			</Card>
		</div>
	)
}

export default Feedback