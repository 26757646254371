import { Badge} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { userIsAdmin } from 'utils/helpers'
import { useAppSelector } from 'stateHandling/hooks'
import React from 'react'
import { DBImage } from 'types/ImageTypes'

interface Props {
	image:DBImage,
	langCode:string
}

const ImageTags = ({ image,langCode }:Props) => {
	const { t } = useTranslation()
	const user = useAppSelector(state => state.user)

	return (
		<div>
			{image.tags.map(obj => {
				if (obj.tags.length > 0) {

					if (userIsAdmin(user.data)){
						return (
							<div key={obj.id}>
								<div data-cy="image_tag_text">{t('images.tags')} ({obj.langCode}):
									<br />
									{obj.tags.map((tag, i) =>
										<Badge
											data-cy="image_tag_badge"
											key={i} className='p-2 m-1'
											bg="primary">
											{tag}
										</Badge>
									)}
								</div>
							</div>
						)
					}
					else {
						if (obj.langCode === langCode){
							return (
								<div key={obj.id}>
									<div data-cy="image_tag_text" >{t('images.tags')}:
										<br />
										{obj.tags.map((tag, i) =>
											<Badge
												data-cy="image_tag_badge"
												key={i} className='p-2 m-1'
												bg="primary">
												{tag}
											</Badge>
										)}
									</div>
								</div>
							)
						}
					}
				}
			})}
		</div>
	)
}

export default ImageTags