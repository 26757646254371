import React,{useEffect, useState } from 'react'
import { DBStatistic } from 'types/StatisticTypes'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'


interface Props {
  filteredStatistics: DBStatistic[];
  interval: 'weekly' | 'monthly' | 'all_time'
}


const StatisticsSummaryBox = ({ filteredStatistics, interval }: Props) => {
	const { t } = useTranslation()

	const [summaryText, setSummaryText] = useState('')

	useEffect(() => {
		switch (interval) {
		case 'weekly':
			setSummaryText(t('patient_statistics.summarybox.summary_week'))
			break
		case 'monthly':
			setSummaryText(t('patient_statistics.summarybox.summary_month'))
			break
		case 'all_time':
		default:
			setSummaryText(t('patient_statistics.summarybox.summary_all_time'))
			break
		}
	}, [interval])

	// Flatten the attempts for easier calculation
	const filteredAttempts = filteredStatistics.flatMap(statistic => statistic.attempts)

	// Calculate total exercises completed
	const totalExercisesCompleted = filteredAttempts.length

	// Calculate total time spent on exercises
	const totalTimeSpent = filteredAttempts.reduce((total, attempt) => {
		const startTime = new Date(attempt.start).getTime()
		const endTime = new Date(attempt.end).getTime()
		return total + (Math.floor(endTime - startTime))
	}, 0)


	// Calculate total correct answers
	const totalPerformance = filteredAttempts.reduce((total, attempt) =>
		total + attempt.answers.filter(answer => answer.correct).length, 0
	)


	// Calculate the total number of questions answered
	const totalQuestionsAnswered = filteredAttempts.reduce((total, attempt) =>
		total + attempt.answers.length, 0
	)


	// Calculate the percentage of correct answers
	const totalCorrectPercentage = totalQuestionsAnswered > 0
		? (totalPerformance / totalQuestionsAnswered) * 100
		: 0


	// Calculate average duration per exercise
	const averageDuration = totalExercisesCompleted > 0 ? totalTimeSpent / totalExercisesCompleted : 0


	// Format time based on translations
	const formatTime = (timeInMillis: number): string => {
		const seconds = Math.floor((timeInMillis / 1000) % 60)
		const minutes = Math.floor((timeInMillis / (1000 * 60)) % 60)
		const hours = Math.floor((timeInMillis / (1000 * 60 * 60)) % 24)
		return `${hours}${t('patient_statistics.time_units.hours')} ${minutes}${t('patient_statistics.time_units.minutes')} ${seconds}${t('patient_statistics.time_units.seconds')}`
	}


	const formattedAverageDuration = formatTime(averageDuration)


	const isAllTime= interval === 'all_time'


	return (
		<div data-cy='statistics_summary_box'>
			<Row className={`${isAllTime ? 'ms-5' : ''}`}>
				<h5 style={{ fontWeight: 'bold', marginBottom: '30px', marginTop: '10px', textAlign: isAllTime ? 'center' : 'left' }}>{summaryText}</h5>
				{isAllTime ? (
					<>
						<Col>
							<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row1'>
								<Col xs={7} sm={7} md={7} lg={7} xl={7} >{t('patient_statistics.summarybox.exercises_completed')}:</Col>
								<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
									{totalExercisesCompleted}</Col>
							</Row>
							<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row2'>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>{t('patient_statistics.summarybox.exercises_total_time')}:</Col>
								<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
									{formatTime(totalTimeSpent)}</Col>
							</Row>
						</Col>
						<Col>
							<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row3'>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>{t('patient_statistics.summarybox.avg_duration')}:</Col>
								<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
									{formattedAverageDuration}
								</Col>
							</Row>
							<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row4'>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>{t('patient_statistics.summarybox.answer_rate')}:</Col>
								<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
									{totalCorrectPercentage.toFixed(0)}%</Col>
							</Row>
						</Col>
					</>
				) : <>
					<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row1'>
						<Col xs={7} sm={7} md={7} lg={9} xl={7} >{t('patient_statistics.summarybox.exercises_completed')}:</Col>
						<Col xs={5} sm={5} md={5} lg={3} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
							{totalExercisesCompleted}</Col>
					</Row>
					<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row2'>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>{t('patient_statistics.summarybox.exercises_total_time')}:</Col>
						<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
							{formatTime(totalTimeSpent)}</Col>
					</Row>
					<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row3'>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>{t('patient_statistics.summarybox.avg_duration')}:</Col>
						<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
							{formattedAverageDuration}
						</Col>
					</Row>
					<Row style={{ marginBottom: '25px' }} data-cy='statistics_summary_box_row4'>
						<Col xs={7} sm={7} md={7} lg={7} xl={7}>{t('patient_statistics.summarybox.answer_rate')}:</Col>
						<Col xs={5} sm={5} md={5} lg={5} xl={5} className="text-right" style={{ fontWeight: 'bold' }}>
							{totalCorrectPercentage.toFixed(0)}%</Col>
					</Row>
				</>}
			</Row>
		</div>
	)
}


export default StatisticsSummaryBox




