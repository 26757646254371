import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-bootstrap'

import ExitButton from 'components/ExitButton'

const ExerciseHeaderDescription = ({ handleExerciseLifecycle }) => {
	const { t } = useTranslation()

	return (
		<>
			<Row xs='auto' className="exercise--header-bg align-items-center p-2 pt-3">
				<Col xs={2} sm={1}>
					<ExitButton
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
				</Col>
                <Col className='flex-grow-1 text-center'>
					<b className='mb-0 exercise--title'>
						{t('exercise.header.description')}
					</b>
				</Col>
			</Row>
		</>
	)
}

export default ExerciseHeaderDescription