import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getPage,setPageNr } from 'reducers/imageSlice'
import { Row, Col, Button} from 'react-bootstrap'
import { getLanguageCode} from 'utils/helpers'


const ImagePagination = () => {
	const { t,i18n } = useTranslation()
	const dispatch = useDispatch()

	const imageData = useSelector(state => state.image)
	const search = useSelector(state => state.image.search)

	/**
     * Responsible for handling a click on the next button for paging.
     */
	const nextPage = () => {
		dispatch(setPageNr(imageData.currentPage + 1))
		dispatch(getPage({
			nextPageBool: true,
			searchCategory: search.category,
			searchTerms: search.term,
			langCode:getLanguageCode(i18n.language)}))
	}

	/**
     * Responsible for handling a click on the previous button for paging.
     */
	const previousPage = () => {
		dispatch(setPageNr(imageData.currentPage - 1))
		dispatch(getPage({
			nextPageBool: false,
			searchCategory: search.category,
			searchTerms: search.term,
			langCode:getLanguageCode(i18n.language)}))
	}

	return (
		<Row className="justify-content-end">
			{/* Simply removed the pagination for now as it does not bring any real value
			<Col lg={8} md={8} xs={6}>
				<h2> {t('images.page')} {imageData.currentPage + 1} / {Math.ceil(imageData.totalCount / imageData.pageLimit)}</h2>
			</Col> */}
			<Col>
				<Button
					variant="secondary"
					className="m-2"
					onClick = {previousPage}
					disabled = {!imageData.hasPrev}>
					{t('images.backButton')}
				</Button>
				<Button
					variant="secondary"
					onClick={nextPage}
					disabled = {!imageData.hasNext}>
					{t('images.nextButton')}
				</Button>
			</Col>
		</Row>
	)
}

export default ImagePagination