import { useTranslation } from 'react-i18next'
import { getLanguageCode} from 'utils/helpers'
import React from 'react'
import { Modal, Button, Card, Badge } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { AWS_URL } from 'utils/config'
import { changeImage, changeAlternativesInput } from 'reducers/newOrEditedExerciseSlice'
import ImageSearch from 'components/ImageSearch'
import ImagePagination from 'components/ImagePagination'
import ImageTags from 'components/ImageTags'

const SelectImageModal = ({ question, idx, j='', closeModal, selectImage }) => {
	// TODO: The padding added is a quickfix for making the modal work with the new left side menu bar. 
	// Do when looking at flow in general.
	const { t,i18n } = useTranslation()
	const dispatch = useDispatch()
	const images = useSelector(state => state.image.images)

	const handleCancel = () => {
		closeModal()
	}

	const handleSelect = (image) => {
		if (j && j !== '') {
			const imageUrl = image.url
			const answerObj = JSON.parse(JSON.stringify(question.answers[j]))
			answerObj.answer.text = imageUrl
			const imageData = { i: idx, j: j, value: answerObj }
			dispatch(changeAlternativesInput(imageData))
		} else {
			const imageData = {image, i: idx}
			dispatch(changeImage(imageData))
		}
		selectImage()
	}

	return (
		<Modal
			show={true}
			onHide={handleCancel}
			backdrop="static"
			keyboard={false}
			centered
			scrollable={true}
			fullscreen={true}
			dialogClassName='create-exercise-page--modal-width'
		>
			<Modal.Header closeButton>
				<Modal.Title>{t('search_images.modal_title')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ImageSearch />
				<ImagePagination />
				<h5>{t('search_images.modal_subtitle')}</h5>
				<div className='images--container'>
					{images.map(image => {
						return (
							<Card key={image.name}
								className="exerciseCard--card"
								onClick={() => handleSelect(image)}>
								<LazyLoadImage
									data-cy="add_exercise_image"
									alt='Image'
									className='exerciseCard--card-img'
									key={AWS_URL + image.url}
									effect="blur"
									height={226}
									placeholderSrc = {AWS_URL + image.url}
									src={AWS_URL + image.url}
								/>
								<Card.Body>
									<Card.Text data-cy="image_category_text">
										{t('images.category')}
										<Badge data-cy="image_category_badge" className='p-2 m-1' bg="secondary">
											{t(`add_image.${image.category}`)}
										</Badge>
										<ImageTags
											image={image}
											langCode={getLanguageCode(i18n.language)}
										/>
									</Card.Text>
								</Card.Body>
							</Card>
						)
					})}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-cy="cancel_select_image_modal"
					variant="secondary"
					onClick={handleCancel}>
					{t('edit_patient.exercises.cancel')}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default SelectImageModal