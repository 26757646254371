import axios from 'axios'
import qs from 'qs'
import { getAuthHeader } from 'utils/authHelper'

const baseUrl = '/api/exercises'

axios.interceptors.request.use(async function (config) {
	const header = await getAuthHeader()
	config.headers = header
	return config
})

/**
 * GET request to get all the exercises from /api/exercises
 * @returns {Array} Array of exercises
 */
const getAll = async (langCode, userId, adultLibrary) => {
	const res = await axios.get(baseUrl, {
		params: {
			langCode: langCode,
			userId: userId,
			adultLibrary: adultLibrary
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	})
	return res.data
}

/**
 * GET request to get all the exercises from /api/exercises
 * @returns {Array} Array of exercises
 */
const getBaseLib = async (langCode, userId, adultLibrary) => {
	const res = await axios.get(`${baseUrl}/baselib`, {
		params: {
			langCode: langCode,
			userId: userId,
			adultLibrary: adultLibrary
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	})
	return res.data
}

/**
 * GET request to get all the community exercises from the library
 * @returns {Array} Array of exercises
 */
const getCommunity = async (langCode, userId, adultLibrary) => {
	const res = await axios.get(`${baseUrl}/community`, {
		params: {
			langCode: langCode,
			userId: userId,
			adultLibrary: adultLibrary
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	})
	return res.data
}

/**
 * 
 * @param {String} id - GET request to get an exercise that matches the id
 * @returns 
 */
const getById = async id => {
	const res = await axios.get(`${baseUrl}/${id}`)
	return res.data
}

const getByIds = async (ids, langCode, adultLibrary) => {
	const res = await axios.get(`${baseUrl}/ids`, {
		params: {
			ids: ids,
			langCode: langCode,
			adultLibrary: adultLibrary
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' })
		}
	})
	return res.data
}

/**
 * POST request to create a new exercise and returns a data of a response from the server
 * @param {FormData} newObject - FormData object containing text and image content of the new exercise 
 * @returns {Object} Newly created exercise data with keys title, questions, data and id
 */
const create = async (newObject) => {
	const res = await axios.post(baseUrl, newObject)
	return res.data
}

/**
 * PUT request to update an existing exercise and returns a data of a response from the server
 * @param {FormData} newObject - FormData object containing text and image content of the updated exercise
 * @returns {Object} Updated exercise data
 */
const update = async (id, changes) => {
	const res = await axios.put(`${baseUrl}/id/${id}`, changes)
	return res.data
}

/**
 * DELETE request to delete an exercise that matches the id
 * Will also 
 * - delete all the questions associated with the exercise
 * - delete all the audio stored from questions in AWS
 * - remove the exercise from the exercise list of user owner
 * @param {String} id - id of the exercise to be deleted
 * @returns 
 */
const deleteById = async (id, userId) => {
	const data = { user: userId }
	const res = await axios.delete(`${baseUrl}/${id}`, {data})
	return res.data
}

export default { getAll, getById, getByIds, create, update, deleteById, getBaseLib, getCommunity }