import React, { useState } from 'react'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { changeCardSort } from 'reducers/previousPageSlice'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ExerciseListViewToggle from 'components/ExerciseLibrarySort/ExerciseListViewToggle'

interface Props {
    numCards: number
}

const ExerciseLibrarySort = ({ numCards }: Props) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const sort = useAppSelector(state => state.previousPage.cardSort)
	const [isOpen, setIsOpen] = useState(false)

	/**
	 * Function to handle changing the language of the exercise library
	 * @param {string} sort - the language that the user is changing to
	 */
	const handleSortChange = (sort: string) => {
		dispatch(changeCardSort(sort))
	}

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	return (
		<Row className='align-items-center my-2' xs='auto' data-cy='exercise_library_sort'>
			<Col className='flex-grow-1' xs={4} sm={6} data-cy='exercise_library_sort_num_exercises'>
				<p>{t('sort_exercises.exercise_number', {numExercises: numCards})}</p>
			</Col>
			<Col className='float-end text-end'>
				<Dropdown show={isOpen} onToggle={toggleDropdown}>
					<Dropdown.Toggle className='exercise-sort--dropdown-button' variant='outline-secondary'
						data-cy='exercise_library_sort_dropdown'
					>
						{t(`sort_exercises.${sort}`)}
						<KeyboardArrowDownIcon />
					</Dropdown.Toggle>
					<Dropdown.Menu data-cy='exercise_library_sort_dropdown_options'>
						<Dropdown.Item
							onClick={() => handleSortChange('alphabetical')}
						>
							{t('sort_exercises.alphabetical')}
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => handleSortChange('alphabetical_reverse')}
						>
							{t('sort_exercises.alphabetical_reverse')}
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => handleSortChange('newest_first')}
						>
							{t('sort_exercises.newest_first')}
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => handleSortChange('oldest_first')}
						>
							{t('sort_exercises.oldest_first')}
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
			<ExerciseListViewToggle/>
		</Row>
	)
}

export default ExerciseLibrarySort