import { useState, useEffect, useRef, useCallback } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faMicrophone, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Hotkeys from 'react-hot-keys'
import { usePageVisibility } from 'hooks/usePageVisibility'
import { getRecorder} from 'recorder/recorder'
import {
	RECORDRTC_ENCODING,
	MEDIARECORDER_ENCODING,
	RECORDRTC_SAMPLE_RATE,
	RECORDRTC_AUDIO_FORMAT,
	MEDIARECORDER_AUDIO_FORMAT
} from 'utils/config'

const ChatbotRecorder = ({ handleAudioSubmit }) => {
	const startIconInstance = <FontAwesomeIcon icon={faMicrophone} />
	const stopIconInstance = <FontAwesomeIcon icon={faEllipsis} beat />
	const loadingIconInstance = <FontAwesomeIcon icon={faSpinner} className='fa-spin' />

	const [recorder, setRecorder] = useState(null)
	const [usingRTCRecorder, setUsingRTCRecorder] = useState(false)
	const [audioStream, setAudioStream] = useState(null)
	const [mimeType, setMimeType] = useState(null)
	const [recorderAlert, setRecorderAlert] = useState(true)
	const [backendError, setBackendError] = useState(false)
	const [audioBits, setAudioBits] = useState(0)
	const [chunks, setChunks] = useState([])
	const [intervalId, setIntervalId] = useState(null)
	const [recordingStartTime, setRecordingStartTime] = useState(null)

	const [recordFunction, setRecordFunction] = useState(true)
	const [recordFunctionIcon, setRecordFunctionIcon] = useState(startIconInstance)
	const [variant, setVariant] = useState('primary')
	const [disabled, setDisabled] = useState(false)

	// Helpers to avoid unwanted updates and re-rendering
	const isFirstRender = useRef(true)
	const answerCollected = useRef(false)

	const isPageVisible = usePageVisibility()

	/**
    * Function to initialize or destroy the RTC recorder based on page visibility
	* Page visibility (meaning if the page / PWA is in the foreground) is
	* determined using the Page Visibility API:
	* https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
    */
	useEffect(() => {
		if (!isFirstRender.current) {
			try {
				if (isPageVisible) {
					loadRtcRecorder()
				} else {
					destroyRtcRecorder()
				}
			} catch (exception) {
				console.error(exception)
			}
		}
	}, [isPageVisible])

	/**
     * Handling recorded audio if using MediaRecorder
	 * Block from running on initial render
     */
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}
		handleRecordedAudio()
	}, [chunks])

	/**
     * Callback for recorder initialization.
     * We need to update when the chunks are updated, otherwise the state is not
     * updated properly in the handleDataAvailable callback.. 
     * When using MediaRecorder, it is necessary to re-assign recorder functions when 'chunks' are updated
     */
	const initRecorder = useCallback(async () => {
		let rec = recorder
		let usingRTC = usingRTCRecorder
		let stream = audioStream
		if (!recorder) {
			[rec, usingRTC, stream] = await getRecorder()
		}
		if (rec) {
			setUsingRTCRecorder(usingRTC)
			setAudioStream(stream)
			setRecorderAlert(false)
			if (usingRTC) {
				setRecorder(rec)
				setAudioBits(RECORDRTC_SAMPLE_RATE)
				setMimeType(RECORDRTC_AUDIO_FORMAT)
			} else {
				setRecorder(Object.assign(
					rec,
					{
						ondataavailable: handleDataAvailable,
						onstop: handleStop,
					},
				))
				setAudioBits(rec.audioBitsPerSecond)
				setMimeType(rec.mimeType)
			}
		} else {
			setRecorderAlert(true)
		}
	}, [chunks])

	/**
     * Firing the callback initializing the recorder
     */
	useEffect(() => {
		try {
			initRecorder()
		} catch (exception) {
			console.error(exception)
		}
	}, [initRecorder])

	/**
	 * Function to re-load the RTC Recorder on navigation from page or app
	 * Sets the states related to the recorder
	 * @returns { void }
	 */
	const loadRtcRecorder = async () => {
		if (usingRTCRecorder) {
			try {
				const [rec, usingRTC, stream] = await getRecorder()
				if (rec && usingRTC) {
					setAudioStream(stream)
					setRecorder(rec)
					setAudioBits(RECORDRTC_SAMPLE_RATE)
					setMimeType(RECORDRTC_AUDIO_FORMAT)
					setRecorderAlert(false)
				} else {
					setRecorderAlert(true)
				}
			} catch (exception) {
				console.error(exception)
				setRecorderAlert(true)
			}
		}
	}

	/**
	 * Function to destroy the RTC Recorder and audioStream
	 * Sets states related to recorder to null
	 * @returns { void }
	 */
	const destroyRtcRecorder = () => {
		if (usingRTCRecorder) {
			setRecorderAlert(true)
			try {
				if (audioStream) {
					audioStream.getTracks().forEach(track => {
						track.stop()
					})
					setAudioStream(null)
				}
				if (recorder) {
					recorder.destroy()
					setRecorder(null)
				}
			} catch (exception) {
				console.error(exception)
			}
		}
	}

	/**
     * Callback function for the recorder ondataavailable event
     * Sets the "chunks" state with the newly recieved data
	 * @returns { void }
     */
	const handleDataAvailable = ({ data }) => {
		const newChunks = [...chunks, data]
		setChunks(newChunks)
	}

	/**
     * Callback function for the recorder onstop event
     */
	const handleStop = () => {
		clearInterval(intervalId)
	}

	/**
     * Start to record audio input
     * @returns {void}
     */
	const start = () => {
		// dispatch(changeAnswerStatus(''))		
		answerCollected.current = false
		try {
			if (usingRTCRecorder) {
				recorder.startRecording()
			} else {
				// Resets the parameters on new recording as it is not always re-rendering
				isFirstRender.current = true
				setChunks([])
				// If adding parameter, dataavailable will be called every <time interval> and when the recorder is stopped.
				recorder.start()
			}
			// Sets an interval that stops the recording after 15s (if it is not stopped before)
			const interval = setInterval(() => { stop() }, 15000)
			setIntervalId(interval)
			const date = new Date()
			setRecordingStartTime(date.toJSON())
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * Stops recording audio input and processes it after that
     * Set a timeout (0.8s) before stop to make sure the recording does not cut short (is there a better solution???)
     */
	const stop = async () => {
		try {
			await new Promise(r => setTimeout(r, 800))
			if (usingRTCRecorder) {
				const state = await recorder.getState()
				if (state === 'recording') {
					await recorder.stopRecording()
					clearInterval(intervalId)
					await handleRecordedAudio()
				}
			} else {
				if (recorder.state === 'recording') {
					recorder.stop()
				}
			}
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * Format the recorded audio segments stored in the chunks state
     * and send the formatted audio through the socket
     * @returns { void }
     */
	const handleRecordedAudio = async () => {
		try {
			let files = {
				audio: {
					encoding: usingRTCRecorder ? RECORDRTC_ENCODING : MEDIARECORDER_ENCODING,
					audioBits: audioBits,
				}
			}

			let blob

			if (usingRTCRecorder) {
				blob = await recorder.getBlob()
				recorder.reset()
				files.audio.type = blob.type
				files.audio.blob = blob
			} else if (chunks.length > 0) {
				let currentMimeType = MEDIARECORDER_AUDIO_FORMAT
				if (mimeType === '' && recorder && recorder.mimeType) {
					currentMimeType = recorder.mimeType
				}
				files.audio.type = currentMimeType
				blob = new Blob(chunks, { type: currentMimeType })
				files.audio.blob = blob
			}
			const formData = new FormData()
			formData.append('file', blob, 'audio.webm')
			await handleAudioSubmit(formData)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
     * Handles whether the recording is on or not, and record button appearance
     */
	const handleRecord = async () => {
		if (recordFunction) {
			await startRecording()
		} else {
			await stopRecording()
		}
	}

	const startRecording = async () => {
		await start()
		setRecordFunction(false)
		setRecordFunctionIcon(stopIconInstance)
		setVariant('danger')
	}

	const stopRecording = async () => {
		setVariant('secondary')
		setDisabled(true)
		setRecordFunction(true)
		setRecordFunctionIcon(loadingIconInstance)
		await stop()
	}

	const onKeyUp = () => {
		stopRecording()
	}

	const onKeyDown = () => {
		startRecording()
	}

	return (
		<>
			<Hotkeys keyName="space" onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<Button
					id="start-recording" className='exercise--record-button rounded-circle' variant={variant} disabled={disabled} onClick={handleRecord}>
					{recordFunctionIcon}
				</Button>
				<h5 className='chatbot--form-label'>Tryck för att spela in</h5>
			</Hotkeys>
		</>
	)
}

export default ChatbotRecorder