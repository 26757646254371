import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import imageService from 'services/images'
import { RootState } from 'stateHandling/configurationStore'
import {ImageTag, ImageUpdates, DBImage } from 'types/ImageTypes'


interface GetPageProps {
	nextPageBool: boolean
	searchCategory: string
	searchTerms: string
	langCode: string
}

interface ImageStatusState {
	loading: boolean
	showMessage: boolean
	message: string
}

interface CurrentEditImage {
	id: string
	name: string
	category: string
	url: string
	tags: ImageTag []
}

interface ImageSearch {
	term: string
	category: string
}

interface ImagePageData {
	imageData: DBImage[],
	totalCount: number,
	startId: string,
	endId: string,
	hasNext: boolean,
	hasPrev: boolean,
}

interface ImageState {
	pageLimit: number,
	currentPage: number,
	totalCount: number,
	startId: string | null,
	endId: string | null,
	hasNext: boolean,
	hasPrev: boolean,
	images: DBImage[],
	currentEditImage: CurrentEditImage
	status: ImageStatusState
	search: ImageSearch
}


export const getPage = createAsyncThunk(
	'image/getPage',
	async (data: GetPageProps, thunkAPI) => {
		const { nextPageBool, searchCategory, searchTerms,langCode } = data
		const state = thunkAPI.getState() as RootState
		try {
			const images = await imageService.getPage(
				state.image.pageLimit,
				nextPageBool,
				state.image.startId!,
				state.image.endId!,
				searchCategory,
				searchTerms,
				langCode
			)
			return images as unknown as ImagePageData
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get images: ${e.response.data}` as string)
		}
	}
)

export const updateImage = createAsyncThunk(
	'image/update',
	async (update: ImageUpdates, thunkAPI) => {
		const state = thunkAPI.getState() as RootState
		try {
			const image = await imageService.update(state.image.currentEditImage.id, update, state.user.data.id)
			return image
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not update image: ${e.response.data}` as string)
		}
	}
)

export const deleteImage = createAsyncThunk(
	'image/delete',
	async (id: string, thunkAPI) => {
		try {
			const res = await imageService.deleteById(id)
			return res as any
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not delete image: ${e.response.data}` as string)
		}
	}
)

const initialState: ImageState = {
	pageLimit: 20,
	currentPage: 0,
	totalCount: 0,
	hasNext: true,
	hasPrev: false,
	startId: null,
	endId: null,
	status: {
		loading: true,
		showMessage: false,
		message: ''
	},
	images: [],
	search: {
		category: 'all',
		term: ''
	},
	currentEditImage: {
		id: '',
		name: '',
		category: '',
		url: '',
		tags: [{ id: '', langCode: '', tags: []}]
	}
}

export const userSlice = createSlice({
	name: 'image',
	initialState,
	reducers: {
		clearImages: (state:ImageState) => {
			state.images = []
			state.currentEditImage = initialState.currentEditImage
		},
		setCurrentEditImage: (state, action) => {
			state.currentEditImage = action.payload
		},
		setCurrentEditImageCategory: (state, action) => {
			state.currentEditImage.category = action.payload
		},
		setCurrentEditImageTags: (state:ImageState, action:PayloadAction<ImageTag>) => {
			const oldTags = state.currentEditImage.tags
			const tags = action.payload.tags
			const langCode = action.payload.langCode
			if (!oldTags.some(t => t.langCode === langCode)) {
				oldTags.push({
					langCode,
					tags: tags
				})
			} else {
				oldTags.map(t => {
					if (t.langCode === langCode) {
						const newTags = [...t.tags, tags[0]]
						return t.tags = newTags
					}
				})
			}
		},
		deleteCurrentEditImageTag: (state:ImageState, action:PayloadAction<ImageTag>) => {
			const tag = action.payload.tags[0]
			const langCode = action.payload.langCode
			const tags = state.currentEditImage.tags.filter(t => t.langCode === langCode).map(t => t.tags)[0]
			const filteredTags = tags.filter(item => item !== tag)
			const i = state.currentEditImage.tags.findIndex(tags => tags.langCode === langCode)
			state.currentEditImage.tags[i].tags = filteredTags
		},
		clearIds: (state:ImageState) => {
			state.startId = null
			state.endId = null
		},
		clearPageNr: (state:ImageState) => {
			state.currentPage = 0
		},
		setPageNr: (state:ImageState, action:PayloadAction<number>) => {
			state.currentPage = action.payload
		},
		setSearchTerm: (state:ImageState, action:PayloadAction<string>) => {
			state.search.term = action.payload
		},
		setSearchCategory: (state:ImageState, action:PayloadAction<string>) => {
			state.search.category = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getPage.fulfilled, (state:ImageState, action:PayloadAction<ImagePageData>) => {
			state.images = action.payload.imageData
			state.totalCount = action.payload.totalCount
			state.startId = action.payload.startId
			state.endId = action.payload.endId
			state.hasNext = action.payload.hasNext
			state.hasPrev = action.payload.hasPrev
			state.status.loading = false
		})
		builder.addCase(getPage.rejected, (state:ImageState, action:PayloadAction<any>) => {
			state.status.loading = false
			state.status.showMessage = true
			state.status.message = action.payload
		})
		builder.addCase(getPage.pending, (state:ImageState) => {
			state.status.loading = true
		})
		builder.addCase(updateImage.fulfilled, (state:ImageState, action:PayloadAction<DBImage>) => {
			const i = state.images.findIndex(img => img.id === action.payload.id)
			state.images[i] = action.payload
			// Clear the image that is selected for edit
			state.currentEditImage = initialState.currentEditImage
		})
		builder.addCase(updateImage.rejected, (state:ImageState, action:PayloadAction<any>) => {
			console.error(action.payload)
		})

		builder.addCase(deleteImage.fulfilled, (state:ImageState, action:PayloadAction<any>) => {
			const tempImages = state.images.filter(img => img.id !== action.payload.id)
			state.images = tempImages
			state.currentEditImage = initialState.currentEditImage
		})
		builder.addCase(deleteImage.rejected, (state:ImageState, action:PayloadAction<any>) => {
			console.error(action.payload)
		})
	}
})

export const {
	clearImages,
	setCurrentEditImage,
	clearIds,
	clearPageNr,
	setPageNr,
	setCurrentEditImageCategory,
	setCurrentEditImageTags,
	deleteCurrentEditImageTag,
	setSearchTerm,
	setSearchCategory
} = userSlice.actions

export default userSlice.reducer