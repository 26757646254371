
import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

interface Props {
	isCompleted: boolean
 }

const ExerciseCardProgressBar = ({ isCompleted }: Props) => {
	return (
		<ProgressBar
			data-cy='patient_exercise_card_progressbar'
			className='exercise--card-progress-bar'
			>
			<ProgressBar
				className={`${isCompleted ? 'exercise--card-progress-sub-bar-complete' : 'exercise--card-progress-sub-bar-incomplete'}`}
				now={100}
				key={1}
			/>
		</ProgressBar>
	)
}

export default ExerciseCardProgressBar