const getDuration = (start) => {
	const startTime = new Date(start)
	const endTime = new Date()
	return Math.round((endTime - startTime) / 1000) // in seconds 
}

const getMinutes = (time) => {
	return Math.floor((time % 3600) / 60)
}

const getSeconds = (time) => {
	return time % 60
}

const getTimeFormatted = (time) => {
	let [hours, minutes, seconds] = [0, 0, 0]
	hours = Math.floor(time / 3600)
	minutes = Math.floor((time % 3600) / 60)
	seconds = (time % 60).toFixed(0)

	if (seconds < 10) {
		seconds = `0${seconds}`
	}

	if (minutes < 10) {
		minutes = `0${minutes}`
	}

	if (hours < 10) {
		hours = `0${hours}`
	}

	return `${hours}:${minutes}:${seconds}`
}

export default { getDuration, getTimeFormatted, getMinutes, getSeconds }