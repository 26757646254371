import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, InputGroup, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
// import { useHotkeys } from 'react-hotkeys-hook'


const Write = ({ submit }) => {
	const { t } = useTranslation()
	const [answer, setAnswer] = useState('')

	/* useHotkeys('enter', (e) => {
		submitAnswer(e)
	}, {
		enableOnFormTags: ['input', 'select', 'textarea']
	}) */

	const handleChange = (event) => {
		event.preventDefault()
		setAnswer(event.target.value)
	}

	const submitAnswer = (event) => {
		event.preventDefault()
		submit(answer)
		setAnswer('')
	}

	return (
		<>
			<Form onSubmit={submitAnswer}>
				<InputGroup data-cy="exercise-question-form">
					<Form.Control
						as="textarea"
						name='answerInput'
						className='exercise--write-input'
						onChange={handleChange}
						value={answer}
						placeholder={t('exercise.write.placeholder')}
						autoFocus
						autoComplete='off'
						data-cy="exercise-question-text-input"
					/>
					<Button
						type='submit'
						variant='primary'
						className='exercise--write-button px-4'
						data-cy="exercise-question-submit"
					>
						<FontAwesomeIcon icon={faCheck}/>
					</Button>
				</InputGroup>
			</Form>
			<h5 className='text-end mt-1'>{t('exercise.write.submit')}</h5>
		</>
	)
}

export default Write