
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from 'react-bootstrap/Alert'
import { ProgressBar, Row, Col } from 'react-bootstrap'
import { getLanguageCode} from 'utils/helpers'
import didNotHearSV from 'sounds/SV-did_not_hear.mp3'
import didNotHearEN_US from 'sounds/EN-US-did_not_hear.mp3'
import didNotHearFI from 'sounds/FI-did_not_hear.mp3'

const StatusRepeat = ({ handleQuestion, tries }) => {

	const {t, i18n } = useTranslation()
	const langCode = getLanguageCode(i18n.language)


	const [show, setShow] = useState(true)
	const [running, setRunning] = useState(true)
	const [progress, setProgress] = useState(0)
	const [interval, setIntervalState] = useState(null)

	const alertInterval = 4000
	const processBarInterval = 100
	const diff = alertInterval/processBarInterval

	useEffect(() => {
		const didNotHearSound = getDidNotHearSound()
		if (tries == 3 || tries == 2) didNotHearSound.play() // don't play sound after 3rd incorrect		
		const timeId = setTimeout(() => {
			setShow(false)
			handleQuestion()
		}, alertInterval)

		return () => {
			clearTimeout(timeId)
		}
	}, [])

	const getDidNotHearSound = () => {
		// Other solution could be to store different mp3-paths in the locales/translation files
		if (langCode === 'sv-SE') {
			return new Audio (didNotHearSV)
		} else if (langCode === 'fi-FI') {
			return new Audio (didNotHearFI)
		} else { // Always default to en-US if no other language-mp3 is imported
			return new Audio (didNotHearEN_US)
		}
	}

	useEffect(() => {

		if (running) {
			setIntervalState(setInterval(() => {
				setProgress((prev) => prev + 100/diff + 0.5)
			}, processBarInterval))
		} else {
			clearInterval(interval)
		}
	}, [running])

	useEffect(() => {
		if (progress === 100) {
			setRunning(false)
			clearInterval(interval)
		}
	}, [progress])

	const progressBar = <ProgressBar>
		<ProgressBar className='exercise--progress-bar1' now={100 - progress} />
	</ProgressBar>

	return (
		<Row className="exercise--footer-bg justify-content-center">
			<Col xs={12} sm={10} md={8} lg={6} xl={5}>
				{show
					? <Alert className='exercise--alert-repeat' variant='warning'>
						{t('exercise.alert.text')} 😊 {progressBar}
					</Alert>
					: null}
			</Col>
		</Row>
	)
	// <Button className='exercise--btn exercise--btn-tryagain' onClick={handleQuestion} variant='warning'>
	// {t("exercise.button_text.try_again")}</Button>
}

export default StatusRepeat