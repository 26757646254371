import { useEffect, useState } from 'react'
import { confidenceColors, getConfidenceColor } from 'utils/helpers'

const Confidence = ({ confidence }) => {    
    const [progress, setProgress] = useState(0)
    const [color, setColor] = useState(null)

    useEffect(() => {                
        setColor(getConfidenceColor(confidence))
        setTimeout(() => setProgress(confidence), 100)                
    }, [confidence])

    const size = 75
    const strokeWidth = 10    

    const viewBox = `0 0 ${size} ${size}`
    const radius = (size - strokeWidth) / 2
    const circumference = radius * Math.PI * 2
    const dash = progress * circumference

    return (
        <div>
            <svg width={size} height={size} viewBox={viewBox}>
                <circle
                    fill='none'
                    stroke={confidenceColors.background}
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                />
                <circle 
                    fill='none'
                    stroke={color}
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${size / 2 } ${size / 2})`}
                    strokeDasharray={[dash, circumference - dash]}
                    strokeLinecap='round'
                    className='exercise--confidenceScore-animation'
                />
                <text                                        
                    x='50%'
                    y='50%'
                    dy='10%'
                    textAnchor='middle'
                    className='exercise--img-feedback-label'
                >
                    {`${Math.round(progress * 100)}`}
                </text>
            </svg>            
        </div>
    )
}

export default Confidence