import { Button, Row, Col } from 'react-bootstrap'
import React, { useEffect } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Hotkeys from 'react-hot-keys'

import Answers from 'components/Answers/Answers'
import CoprehensionAnswers from 'components/ComprehensionAnswers'

import correctAnswer from 'sounds/correct_answer_1.mp3'
import wrongAnswer from 'sounds/wrong_answer.mp3'

const StatusNext = ({ handleQuestion, isLast }) => {
	const { t } = useTranslation()
	const stats = useAppSelector(state => state.currentExercise.stats)
	useEffect(() => {
		playSound()
	}, [stats.status])

	const playSound = () => {
		const answerCorrect = stats.answerStatus === 'correct'
		if (answerCorrect) {
			const correctSound = getSound(true)
			if (correctSound) correctSound.play()
		} else {
			const wrongSound = getSound(false)
			if (wrongSound) wrongSound.play()
		}
	}

	const getSound = correct => {
		if (correct) return new Audio (correctAnswer)
		return new Audio (wrongAnswer)
	}

	const onKeyDown = (keyName, event) => {
		event.preventDefault()
		handleQuestion()
	}

	return (
			<Row className='exercise--footer-fixed justify-content-center'>
				<Col className='text-center flex-grow-1 align-self-center'>
					<Hotkeys keyName="enter" onKeyDown={onKeyDown}>
						<Button
							className='text-nowrap exercise--next-button px-5 py-4 fs-5'
							onClick={handleQuestion} variant='primary'
							data-cy='exercise_question_next_button'
						>
							<FontAwesomeIcon className='exercise--next-button-icon' icon={faArrowRight} />
							{isLast
								? t('exercise.button_text.view_results')
								: t('exercise.button_text.next_question')
							}
						</Button>
					</Hotkeys>
				</Col>
			</Row>
	)
}

export default StatusNext