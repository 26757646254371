import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'

interface Props {
    setShowRemoveAssignedModal(show: boolean): void
    handleRemoveAssigned(): void
}

const RemoveAssignedConfirmModal = ({setShowRemoveAssignedModal, handleRemoveAssigned }: Props) => {

	const { t } = useTranslation()

	const handleProceed = () => {
		setShowRemoveAssignedModal(false)
		handleRemoveAssigned()
	}

	return (
		<>
			<Modal data-cy='confirm_remove_selected' show={true}>
				<Modal.Header>
					<Modal.Title>{t('edit_patient.exercises.remove_selected_modal.title')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>{t('edit_patient.exercises.remove_selected_modal.body')}</p>
				</Modal.Body>

				<Modal.Footer>
					<Button className='exercise-library--secondary-button' variant='outline-secondary'
						data-cy='cancel_remove_assigned'
						onClick={() => setShowRemoveAssignedModal(false)}
					>
						{t('edit_patient.exercises.remove_selected_modal.button_cancel')}
					</Button>
					<Button data-cy='confirm_remove_assigned' variant='primary'
						onClick={handleProceed}
					>
						{t('edit_patient.exercises.remove_selected_modal.button_proceed')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default RemoveAssignedConfirmModal