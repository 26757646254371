import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'react-bootstrap'

interface Props {
	handleClose:() => void
}

const ConfirmDeleteModal = ({ handleClose }: Props) => {

	const { t } = useTranslation()


	const deleteAccount = async () => {
		// TODO: dispatch when delete function is implemented
		// dispatch(deleteAdminUser(user.id))
	}

	return (

		<>
			<Modal show={true} data-cy='delete-account-modal'>
				<Modal.Header closeButton onClick={handleClose}>
					<Modal.Title>{t('edit_user.confirm_delete_title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{t('edit_user.confirm_delete_message')}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose} variant="outline-dark" data-cy='cancel-delete-button'>
						{t('edit_user.cancel')}
					</Button>
					<Button onClick={deleteAccount} variant="danger" disabled data-cy='delete-account-button'>
						{t('edit_user.confirm_delete_yes')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmDeleteModal