import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { getConfidenceBgColor, getConfidenceColor } from 'utils/helpers'



const WrittenAnswer = () => {
    const answer = useSelector(state => state.currentExercise.writtenAnswer)
    const confidence = useSelector(state => state.currentExercise.stats.confidence)
    

    return (
        <div className='exercise--feedback-writtenAnswer p-2 text-start'
            style={{backgroundColor: getConfidenceBgColor(confidence), borderColor: getConfidenceColor(confidence)}}
        >
            <FontAwesomeIcon className='fs-6 px-2' icon={faPen} />
            {answer}
        </div>
    )
}

export default WrittenAnswer