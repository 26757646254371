import React, { ChangeEvent} from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { Table, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import LanguageIcon from '@mui/icons-material/Language'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import { DBStatistic } from 'types/StatisticTypes'
import EqualizerIcon from '@mui/icons-material/Equalizer'

import { tagColor, filterIconColor, getExerciseSelectAttribute, getAnswerFormat } from 'utils/helpers'
import { LibraryExercise } from 'types/ExerciseTypes'
import { AdminUser } from 'types/UserTypes'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import SingleExerciseStatisticsModal from 'components/Statistics/SingleExerciseStatisticsModal'

import dialogLogo from 'images/logos/thumbnail.png'
import './ExerciseLibraryTable.css'

interface Props {
    exercises: LibraryExercise[]
    baseLib: boolean
	viewExercise: (id: string, renderType: string, baseLib:boolean) => any
    startExercise: (id: string, renderType: string) => void
	getIcon: (answerFormat: string) => IconProp
	assign: boolean
	selectedExercises: LibraryExercise[]
	exerciseSelect: (event: ChangeEvent<HTMLInputElement>, exercise: LibraryExercise, renderType: string) => void
	showPlay: boolean
	showStatistics: boolean
	filteredPatientStatistics: DBStatistic[]
	toggleShowStatistics: (exerciseId: string) => void
	handleCloseStatisticsModal: () => void
}

const ExerciseLibraryTable = ({
	exercises, baseLib, viewExercise, startExercise, getIcon, assign, selectedExercises, exerciseSelect, showPlay,showStatistics, filteredPatientStatistics, toggleShowStatistics,handleCloseStatisticsModal
}: Props) => {
	const { t } = useTranslation()
	const user = useAppSelector(state => state.user)

	/*
	Detect if the exercise is created by the current user
	Set the state-variable that controls the card header
	*/
	const ownExercise = (id: string) => {
		const adminUser = user.data as AdminUser
		return adminUser.exercises.includes(id)
	}

	const checkExerciseSelection = (exercise: LibraryExercise) => {
		const matchingExercise = selectedExercises.filter(e => e.id === exercise.id)[0]
		return matchingExercise ? matchingExercise[getExerciseSelectAttribute[exercise.renderType]] : false
	}

	/**
	 * Returns the triangle indicating base lib, own exercise or community exercise
	 * @returns { JSX.Element }
	 */
	const getTriangle = (id: string, baseLib) => {
		if (baseLib) {
			return (
				<div
					className='exercise-library--row-base-lib-icon'
					data-cy='exercise_library_table_row_baselib_icon'
				>
					<img
						src={dialogLogo}
						className='icon'
						alt="Dialog logo"
					/>
					<div className='corner-box'></div>
				</div>
			)
		} else if (ownExercise(id)) {
			return (
				<div
					className='exercise-library--row-own-exercise-icon'
					data-cy='exercise_library_table_row_my_icon'
				>
					<PersonIcon className='icon'/>
					<div className='corner-box'></div>
				</div>
			)
		}
		return (
			<div
				className='exerciseLibraryCard--row-community-icon'
				data-cy='exercise_library_table_row_community_icon'
			>
				<LanguageIcon className='icon'/>
				<div className='corner-box'></div>
			</div>
		)
	}

	return (
		<>
			<Table hover responsive className={assign ? 'mb-5' : ''} data-cy='exercise_library_table'>
				<tbody className='font-bold'>
					<SingleExerciseStatisticsModal
						showStatistics={showStatistics}
						handleCloseStatisticsModal={handleCloseStatisticsModal}
						filteredPatientStatistics={filteredPatientStatistics}
					/>
					{exercises.map((e, i) => {
						return (
							<tr key={i} className='exercise-library--row' data-cy='exercise_library_table_row'>
								{assign
									? <td>
										<input
											key={i}
											onChange={(event) => exerciseSelect(event, e, e.renderType)}
											checked={checkExerciseSelection(e)}
											type='checkbox'
											data-cy={`exercise_select_${e.id}`}
											className='exercise-library--table-exercise-select'
										/>
									</td>
									: <></>
								}
								<td
									className='exercise-library--table-row-title'
									data-cy='exercise_library_table_row_title'
								>
									<div className="d-inline exerciseLibraryCard--title-icon">
										<FontAwesomeIcon
											style={{ color: filterIconColor[e.renderType] }}
											icon={getIcon(e.renderType)}
										/>
									</div>
									{e.title.text}
									{!e.settings.isPublic
										? <LockIcon data-cy='private_exercise_icon' className='d-inline mx-2 exerciseLibraryCard--private-icon'/>
										: <></>
									}
								</td>
								<td>{t(`exercise_card.format.${e.template}`)}</td>
								<td>{t(`exercise_card.alternative_format.${getAnswerFormat(e, e.renderType)}`)}</td>
								<td>{t(`create_exercise.settings.category.${e.category}`)}</td>
								<td>
									<Row xs="auto">
										{e.subtype.map(type => {
											return (
												<Col key={type} className='m-2 exerciseLibraryCard--tag text-nowrap'
													style={{
														color: tagColor[type]
															? tagColor[type].color : tagColor.default.color,
														backgroundColor: tagColor[type]
															? tagColor[type].bgColor : tagColor.default.bgColor,
														borderColor: tagColor[type]
															? tagColor[type].borderColor : tagColor.default.borderColor
													}}
												>
													{t(`exercise_card.subtypes.${type}`)}
												</Col>
											)})
										}
									</Row>
								</td>
								<td className='exercise-library--table-row-action-col'>
									<OverlayTrigger
										key={`preview-tooltip-${e.id}`}
										trigger={['hover', 'focus']}
										placement='top'
										overlay={
											<Tooltip id={`preview-tooltip-${e.id}`}>
												{t('exercise_library_card.preview_exercise_tooltip')}
											</Tooltip>
										}>
										<FactCheckIcon
											onClick={viewExercise(e.id, e.renderType, e.baselib ? e.baselib : baseLib)}
											className='exerciseLibraryCard--icon-button'
											data-cy='exercise_library_table_preview_button'
										/>
									</OverlayTrigger>
								</td>
								{showPlay
									? <td className='exercise-library--table-row-action-col'>
										<OverlayTrigger
											key={`start-tooltip-${e.id}`}
											trigger={['hover', 'focus']}
											placement='top'
											overlay={
												<Tooltip id={`start-tooltip-${e.id}`}>
													{t('exercise_library_card.start_exercise_tooltip')}
												</Tooltip>
											}>
											<PlayArrowRoundedIcon
												onClick={() => startExercise(e.id, e.renderType)}
												className='exerciseLibraryCard--icon-button'
												data-cy='exercise_library_table_play_button'
											/>
										</OverlayTrigger>
									</td>
									:<></>
								}
								{assign
									? <td className='exercise-library--table-row-action-col'>
										<OverlayTrigger
											key={`start-tooltip-${e.id}`}
											trigger={['hover', 'focus']}
											placement='top'
											overlay={
												<Tooltip id={`start-tooltip-${e.id}`}>
													{t('exercise_library_card.show_statistics_tooltip')}
												</Tooltip>
											}>
											<EqualizerIcon
												className='exerciseLibraryCard--icon-button'
												onClick={() => toggleShowStatistics(e.id)}
												data-cy='exercise_library_table_statistics_button'
											/>
										</OverlayTrigger>
									</td>
									:<></>
								}
								<td className='exercise-library--table-row-type-col'>
									{getTriangle(e.id, e.baselib ? e.baselib : baseLib)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</>
	)
}

export default ExerciseLibraryTable