import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Popover, OverlayTrigger, Button, Col, Row } from 'react-bootstrap'

import StatusBar from 'components/StatusBar'
import ExitButton from 'components/ExitButton'
import AudioPlayer from 'components/AudioPlayer/AudioPlayer'

const ExerciseHeader = ({ handleSkip, handleExerciseLifecycle }) => {
	const { t } = useTranslation()
	const exercise = useAppSelector(state => state.currentExercise.exercise)
	const stats = useAppSelector(state => state.currentExercise.stats)
	const exerciseLength = useAppSelector(state => state.currentExercise.length)
	const exerciseAudio = useAppSelector(state => state.exerciseList.exerciseAudio)

	const popover = (
		<Popover className='exercise--header-description-popover' data-cy='exercise_header_desc_popover'>
			<Popover.Header>{exercise.title.text}</Popover.Header>
			<Popover.Body>
				<Row>
					<Col>
						<AudioPlayer
							buffer={exerciseAudio[exercise.id] ? exerciseAudio[exercise.id].description.patient.audio : []}
							large={false}
							text={true}
						/>
					</Col>
					<Col xs={12} md={8}>
						{exercise.description.patient.text}
					</Col>
				</Row>
			</Popover.Body>
		</Popover>
	)

	/**
     * Generates data for the progress bar
     * @returns {String} - label to be displayed in the progress bar
     */
	const progressData = () => {
		const label = stats.index < exerciseLength
			? `${stats.index + 1} / ${exerciseLength}`
			: `${t('exercise.info.status_all_done')}`
		return label
	}

	return (
		<>
			<Row xs='auto' className="exercise--header-bg align-items-center p-2 pt-3">
				<Col xs={2} sm={1}>
					<ExitButton
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
				</Col>
				<Col className='flex-grow-1 text-center'>
					<b className='mb-0 exercise--title' data-cy='exercise_header_title'>
						{t('exercise.header.question_num')}: {progressData()}
					</b>
				</Col>
				<Col className="text-end" xs={3} md={2}>
					<OverlayTrigger trigger="click" placement='bottom' overlay={popover} rootClose>
						<Button
							className='text-nowrap mx-2 exercise--header-button'
							variant='outline-primary' data-cy='exercise_header_desc_button'
						>
							<FontAwesomeIcon className='mx-1' icon={faCircleInfo} />
							{t('exercise.header.description')}
						</Button>
					</OverlayTrigger>
				</Col>
				<Col>
					{stats.status === 'none' /** || status === 'incorrect' // additional modification needed to use this */
						? <Col className="text-end" xs={3} md={2}>
							<Button
								className='text-nowrap mx-2 exercise--header-button'
								variant={'outline-primary'} onClick={handleSkip}
								data-cy='exercise_header_skip_button'
							>
								<FontAwesomeIcon className='mx-1' icon={faArrowRight}/>
								{t('exercise.header.skip')}
								</Button>
						</Col>
						: <></>
					}
				</Col>
			</Row>
			<StatusBar />
		</>
	)
}

export default ExerciseHeader