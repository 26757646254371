import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'

const Tags = ({ tags, deleteTag, i='0' }) => {
	return (
		<Container>
			{tags
				? tags.map(t => {
					return (
						<Badge key={t} className='p-2 m-1' bg="primary" onClick={deleteTag(t, i)} data-cy={`tag-${t}`}>
							{t} ✕
						</Badge>
					)
				})
				: <></>
			}
		</Container>
	)
}

export default Tags