import {Modal, Button} from 'react-bootstrap'
import React from 'react'
import { DBStatistic } from 'types/StatisticTypes'
import { useTranslation } from 'react-i18next'
import SingleExerciseStatisticsTable from 'components/Statistics/SingleExerciseStatisticTable'

interface Props {
    showStatistics: boolean
    handleCloseStatisticsModal: () => void
    filteredPatientStatistics: DBStatistic[]
}

const SingleExerciseStatisticsModal= ({showStatistics,handleCloseStatisticsModal,filteredPatientStatistics }: Props) => {
	const { t } = useTranslation()

	return (
		<Modal show={showStatistics} onHide={() => handleCloseStatisticsModal}>
			<Modal.Header>
				<Button
					variant='secondary'
					onClick={() => handleCloseStatisticsModal()}
					data-cy='close_statistics_modal_button'
				>
					{t('patient_statistics.close_statistics_modal')}
				</Button>
			</Modal.Header>
			<Modal.Body>
				<SingleExerciseStatisticsTable filteredPatientStatistics={filteredPatientStatistics}></SingleExerciseStatisticsTable>
			</Modal.Body>
		</Modal>
	)

}

export default SingleExerciseStatisticsModal

