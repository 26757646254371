
import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { DBStatistic } from 'types/StatisticTypes'
import { useTranslation } from 'react-i18next'

const PatientTotalProgressBar = () => {

	const { t } = useTranslation()
	const filteredExercises = useAppSelector(state => state.exerciseList.filteredExercises)
	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const [percentageOfCompletedExercises, setPercentageOfCompletedExercises] = useState(0)
	const [completedLabel, setCompletedLabel] = useState('')
	const [nrOfExerciseCards, setNrOfExerciseCards] = useState(0)

	useEffect(() => {
		if (filteredExercises.length > 0) {
			let nrOfExerciseCards = 0
			filteredExercises.forEach((exercise) => {

				if (exercise.useListening) {nrOfExerciseCards ++}
				if (exercise.useReading) {nrOfExerciseCards ++}
				if (exercise.useWriting) {nrOfExerciseCards ++}
				if (exercise.useSpeaking) {nrOfExerciseCards ++}
			})
			setNrOfExerciseCards(nrOfExerciseCards)
		}
	}, [filteredExercises])

	useEffect(() => {
		let nrOfCompletedExercises = 0
		const patientStatisticsArray = Object.values(patientStatistics)

		filteredExercises.map((exercise) => {
			const statistics = patientStatisticsArray.filter((statistic) => {
				return statistic.exerciseId === exercise.id
						&& exerciseHasAnswerFormat(exercise, statistic.answerFormat)
			})

			// Needed since we can have same exercise in several types (write/speak etc.) --> treated as several exercises
			statistics.forEach((statistic) => {
				if (exerciseIsCompleted(statistic)) {
					nrOfCompletedExercises += 1
				}
			})
		})

		if (nrOfExerciseCards !== 0) {
			setPercentageOfCompletedExercises(nrOfCompletedExercises/nrOfExerciseCards * 100)
		} else {
			setPercentageOfCompletedExercises(0)
		}
	}, [patientStatistics])

	const exerciseIsCompleted = (statistic:DBStatistic) => {
		const firstCompletedAttempt = statistic.attempts.find((attempt) => attempt.completed === true)
		return firstCompletedAttempt !== undefined
	}

	/**
	 * @param {ExerciseForAssignment} exercise assigned to patients, with some answer formats
	 * @param {String} answerFormat the answerFormat for a statistics object
	 * @returns {Boolean} - true if the answerFormat of the exercise match the answerFormat of the current statistics object
	 */
	const exerciseHasAnswerFormat = (exercise, answerFormat) => {
		return exercise.useSpeaking && answerFormat == 'speak'
		|| exercise.useWriting && answerFormat == 'write'
		|| exercise.useListening && answerFormat == 'listen'
		|| exercise.useReading && answerFormat == 'read'
	}

	useEffect(() => {
		const percentageRounded = Math.round(percentageOfCompletedExercises)
		setCompletedLabel(t('total_progress_bar.percentage',{
			percentageRounded: percentageRounded
		}))
	}, [percentageOfCompletedExercises])


	return (
		<>
			<h2 className="exercisePage--completed-label">{completedLabel}</h2>
			<ProgressBar data-cy='exercise_page_total_progressbar' className='exercisePage--total-progress-bar mb-5'>
				<ProgressBar className='exercisePage--total-progress-bar1'
					now={percentageOfCompletedExercises}
				/>
				<ProgressBar className='exercisePage--total-progress-bar'
					now={100 - percentageOfCompletedExercises}
				/>
			</ProgressBar>

		</>
	)
}

export default PatientTotalProgressBar