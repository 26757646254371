import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'

import ExerciseTextAndAudioPrompt from 'components/ExercisePrompt/ExerciseTextAndAudioPrompt/ExerciseTextAndAudioPrompt'
import Answers from 'components/Answers/Answers'
import ExerciseFeedback from 'components/ExerciseFeedback/ExerciseFeedback'

import { EXERCISE_PROGRESS_STATUS_NEXT } from 'utils/config'
import { FillAudioBuffer, FillContentQuestionState } from 'types/QuestionTypes'


const ExercisePromptFill = () => {

	const question = useAppSelector(state => state.currentExercise.currentQuestion) as FillContentQuestionState
	const currentAudioBuffer = useAppSelector(state => state.currentExercise.currentAudioBuffer) as FillAudioBuffer
    const status = useAppSelector(state => state.currentExercise.stats.status)

	return (
        <>
            {status === EXERCISE_PROGRESS_STATUS_NEXT
                ? <Row className='exercise--div-question mb-2 flex-grow-1'>
					<Col xs={12} sm={6} className='px-5 exercise--prompt-border'>
						<ExerciseTextAndAudioPrompt
							questionText={question.fullSentence!.text}
							questionAudio={currentAudioBuffer.fullSentence}
						/>
					</Col>
					<Col xs={12} sm={6} className='px-5'>
						<ExerciseFeedback />
						<Answers />
					</Col>
                </Row>
                : <>
					<ExerciseTextAndAudioPrompt
						questionText={question.sentence!.text}
						questionAudio={currentAudioBuffer.sentence}
					/>
				</>
            }
        </>
	)
}

export default ExercisePromptFill