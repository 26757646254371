import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import ChatbotScenarioCard from 'components/Chatbot/ChatbotScenarioCard'


interface Props {
	selectScenario: (id: number, format: string) => Promise<void>
}

const ChatbotScenarioList = ({ selectScenario }:Props) => {
	
	const scenarios = useAppSelector(state => state.chatbot.scenarios)

	return (
		<div>
			<div className='chatbot--scenario-list-container'>
				{scenarios.map((scenario, id) =>
					<ChatbotScenarioCard
						key={id}
						id={id}
						selectScenario={selectScenario}
					/>
				)}
			</div>
		</div>
	)
}

export default ChatbotScenarioList