import adminUserPool from 'utils/adminUserPool'
import patientUserPool from './patientUserPool'

/**
 * Using AWS Cognito to get a session for a user
 * @returns {Object} Session if one is ongoing, else false
 */
export const getUserSession = async () => {
	try {
		const session = await new Promise((resolve, reject) => {
			// TODO: figure out a better way to know if admin / patientuser..
			let user = patientUserPool.getCurrentUser()
			if (!user) user = adminUserPool.getCurrentUser()
			if (user) {
				user.getSession((err, session) => {
					if (err) {
						reject(err)
					} else {
						resolve(session)
					}
				})
			} else {
				reject()
			}
		})
		return session
	} catch (err) {
		console.error('Could not get user session: ', err)
		return false
	}
}