import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminUser, AssignedExercise, PatientUser, UserState } from 'types/UserTypes'
import exerciseService from 'services/exercises'
import { userIsAdminOrSlp } from 'utils/helpers'
import { DBExercise, ExerciseForAssignment, ExerciseAudio, ExercisesAudio } from 'types/ExerciseTypes'

// Good source for using createAsyncThunk with TS: https://redux-toolkit.js.org/usage/usage-with-typescript

interface Search {
	term: string
	skill: string
	type: string[]
	subtype: string[]
	template: string[]
	category: string[]
	difficulty: string[]
}

interface ExerciseListState {
	filterType: string
	communityExercises: DBExercise[]
	editExercises: DBExercise[]
	baseLibExercises: DBExercise[]
	exercises: ExerciseForAssignment[]
	filteredExercises: ExerciseForAssignment[]
	loading: boolean
	search: Search
	exerciseAudio: ExercisesAudio
}

export const getExerciseList = createAsyncThunk(
	'user/getExerciseList',
	async ({ user, langCode, adultLibrary }: { user: UserState, langCode: string, adultLibrary: boolean }, thunkAPI) => {
		try {
			let exercises
			/* If the user type is a user, get either assigned exercises or base library */
			if (user.data.type === 'user') {
				const patientUser = user.data as PatientUser
				if ('settings' in patientUser && patientUser.settings.assignedExercises) {
					const tempExercises:DBExercise[] =
						await exerciseService.getByIds(patientUser.exercises.map(e => e.exerciseId))
					exercises = tempExercises.map(e => {
						const answerFormats = (patientUser.exercises as AssignedExercise[])
							.filter(a => a.exerciseId === e.id)[0].answerFormat
						e['useSpeaking'] = answerFormats.includes('speak')
						e['useWriting'] = answerFormats.includes('write')
						e['useListening'] = answerFormats.includes('listen')
						e['useReading'] = answerFormats.includes('read')
						return e
					})
				} else {
					const tempExercises:DBExercise[] = await exerciseService.getBaseLib(langCode, patientUser.id, patientUser.isAdult)
					exercises = tempExercises.map(e => {
						e['useSpeaking'] = e.type.includes('speak')
						e['useWriting'] = e.type.includes('write')
						e['useListening'] = e.type.includes('listen')
						e['useReading'] = e.type.includes('read')
						return e
					})
				}
			/* If the user type is slp or admin, get all own and community exercises */
			} else {
				const tempExercises:DBExercise[] = await exerciseService.getAll(langCode, user.data.id, adultLibrary)
				exercises = tempExercises.map(e => {
					e['useSpeaking'] = e.type.includes('speak')
					e['useWriting'] = e.type.includes('write')
					e['useListening'] = e.type.includes('listen')
					e['useReading'] = e.type.includes('read')
					return e
				})
			}
			return exercises
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get exercises: ${e.response.data}`)
		}
	}
)

export const getExerciseEditList = createAsyncThunk(
	'user/getExerciseEditList',
	async ({ user, langCode, adultLibrary }: { user: AdminUser, langCode: string, adultLibrary: boolean}, thunkAPI) => {
		try {
			if (userIsAdminOrSlp(user)) {
				const tempExercises:DBExercise[] = await exerciseService.getByIds(user.exercises, langCode, adultLibrary)
				const exercises = tempExercises.map(e => {
					e['useSpeaking'] = e.type.includes('speak')
					e['useWriting'] = e.type.includes('write')
					e['useListening'] = e.type.includes('listen')
					e['useReading'] = e.type.includes('read')
					return e
				})
				return exercises
			} else {
				return thunkAPI.rejectWithValue(`Users with type ${user.type} cannot edit any exercises`)
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get exercises to edit: ${e.response.data}`)
		}
	}
)

export const getExerciseBaseLib = createAsyncThunk(
	'user/getExerciseBaseLib',
	async ({ user, langCode, adultLibrary }: { user: UserState, langCode: string, adultLibrary: boolean }, thunkAPI) => {
		try {
			const tempExercises:DBExercise[] = await exerciseService.getBaseLib(langCode, user.data.id, adultLibrary)
			const exercises = tempExercises.map(e => {
				e['useSpeaking'] = e.type.includes('speak')
				e['useWriting'] = e.type.includes('write')
				e['useListening'] = e.type.includes('listen')
				e['useReading'] = e.type.includes('read')
				return e
			})
			return exercises
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get base library exercises: ${e.response.data}`)
		}
	}
)

export const getCommunityExercises = createAsyncThunk(
	'user/getCommunityExercises',
	async ({ user, langCode, adultLibrary }: { user: UserState, langCode: string, adultLibrary: boolean }, thunkAPI) => {
		try {
			const tempExercises:DBExercise[] = await exerciseService.getCommunity(langCode, user.data.id, adultLibrary)
			const exercises = tempExercises.map(e => {
				e['useSpeaking'] = e.type.includes('speak')
				e['useWriting'] = e.type.includes('write')
				e['useListening'] = e.type.includes('listen')
				e['useReading'] = e.type.includes('read')
				return e
			})
			return exercises
		} catch (e) {
			return thunkAPI.rejectWithValue(`Could not get community exercises: ${e.response.data}`)
		}
	}
)

const initialState: ExerciseListState = {
	filterType: 'all',
	communityExercises: [],
	editExercises: [],
	baseLibExercises: [],
	exercises: [],
	filteredExercises: [],
	loading: false,
	search: {
		term: '',
		skill: 'all',
		type: ['all'],
		subtype: ['all'],
		template: ['all'],
		category: ['all'],
		difficulty: ['all'],
	},
	exerciseAudio: {}
}

export const exerciseListSlice = createSlice({
	name: 'exerciseList',
	initialState,
	reducers: {
		changeExerciseFilterType: (state:ExerciseListState, action:PayloadAction<string>) => {
			state.filterType = action.payload
		},
		changeFilteredExercises: (state:ExerciseListState, action:PayloadAction<ExerciseForAssignment[]>) => {
			state.filteredExercises = action.payload
		},
		clearExerciseList: (state:ExerciseListState) => {
			state.exercises = initialState.exercises
			state.communityExercises = initialState.communityExercises
			state.baseLibExercises = initialState.baseLibExercises
			state.filteredExercises = initialState.filteredExercises
			state.filterType = initialState.filterType
		},
		setSearchTerm: (state:ExerciseListState, action:PayloadAction<string>) => {
			state.search.term = action.payload
		},
		setSearchSkill: (state:ExerciseListState, action:PayloadAction<string>) => {
			state.search.skill = action.payload
		},
		setSearchType: (state:ExerciseListState, action:PayloadAction<string[]>) => {
			state.search.type = action.payload
		},
		setSearchSubtype: (state:ExerciseListState, action:PayloadAction<string[]>) => {
			state.search.subtype = action.payload
		},
		setSearchTemplate: (state:ExerciseListState, action:PayloadAction<string[]>) => {
			state.search.template = action.payload
		},
		setSearchCategory: (state:ExerciseListState, action:PayloadAction<string[]>) => {
			state.search.category = action.payload
		},
		setSearchDifficulty: (state:ExerciseListState, action:PayloadAction<string[]>) => {
			state.search.difficulty = action.payload
		},
		resetSearch: (state:ExerciseListState) => {
			state.search = initialState.search
		},
		changeExerciseAudio: (state:ExerciseListState, action:PayloadAction<{id: string, audio: ExerciseAudio}>) => {
			state.exerciseAudio[action.payload.id] = action.payload.audio
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getExerciseList.fulfilled, (state:ExerciseListState, action:PayloadAction<ExerciseForAssignment[]>) => {
			state.exercises = action.payload
			state.loading = false
		})
		builder.addCase(getExerciseList.rejected, (state:ExerciseListState, action:PayloadAction<any>) => {
			console.error(action.payload)
			state.loading = false
		})
		builder.addCase(getExerciseList.pending, (state:ExerciseListState) => {
			state.loading = true
		})
		builder.addCase(getExerciseEditList.fulfilled, (state:ExerciseListState, action:PayloadAction<DBExercise[]>) => {
			state.editExercises = action.payload
			state.loading = false
		})
		builder.addCase(getExerciseEditList.rejected, (state:ExerciseListState, action:PayloadAction<any>) => {
			console.error(action.payload)
			state.loading = false
		})
		builder.addCase(getExerciseEditList.pending, (state:ExerciseListState) => {
			state.loading = true
		})
		builder.addCase(getExerciseBaseLib.fulfilled, (state:ExerciseListState, action:PayloadAction<DBExercise[]>) => {
			state.baseLibExercises = action.payload
			state.loading = false
		})
		builder.addCase(getExerciseBaseLib.rejected, (state:ExerciseListState, action:PayloadAction<any>) => {
			console.error(action.payload)
			state.loading = false
		})
		builder.addCase(getExerciseBaseLib.pending, (state:ExerciseListState) => {
			state.loading = true
		})
		builder.addCase(
			getCommunityExercises.fulfilled, (state:ExerciseListState, action:PayloadAction<DBExercise[]>) => {
				state.communityExercises = action.payload
				state.loading = false
			})
		builder.addCase(getCommunityExercises.rejected, (state:ExerciseListState, action:PayloadAction<any>) => {
			console.error(action.payload)
			state.loading = false
		})
		builder.addCase(getCommunityExercises.pending, (state:ExerciseListState) => {
			state.loading = true
		})
	}
})

export const {
	changeExerciseFilterType,
	changeFilteredExercises,
	clearExerciseList,
	setSearchTerm,
	setSearchSkill,
	setSearchType,
	setSearchSubtype,
	setSearchTemplate,
	setSearchCategory,
	setSearchDifficulty,
	resetSearch,
	changeExerciseAudio
} = exerciseListSlice.actions

export default exerciseListSlice.reducer