import React from 'react'
import PlayAudio from 'components/Chatbot/PlayAudio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col} from 'react-bootstrap'
import { faRobot } from '@fortawesome/free-solid-svg-icons'

interface Props {
	message:string
	audioBuffer: Uint8Array
	isThinking: boolean
	showConfirmation: boolean
	autoplay: boolean

}
const convertMarkdownToPlainText = (text:string) => {
	// Regular expressions for bold text and headers
	const boldRegex = /\*\*(.*?)\*\*/g
	const headerRegex = /###\s*(.*?)(?=\n|$)/g

	// Replace the Markdown syntax with plain text
	const plainText = text
		.replace(headerRegex, '$1')  // Remove '### ' but keep the header text
		.replace(boldRegex, '$1')   // Remove '**' but keep the bold text

	return plainText
}

const AIMessage = ({ message, audioBuffer, isThinking, showConfirmation,autoplay }:Props )=> {

	const convertedMessage = convertMarkdownToPlainText (message)
	const formattedMessage = convertedMessage.split('\n').map((line, index) => (
		<React.Fragment key={index}>
			{line}
			<br />
		</React.Fragment>
	))

	return (
		<Row xs='auto' className='chatbot--ai-message-container'>
			<Col xs={2} className='align-self-end'>
				<FontAwesomeIcon className='chatbot--ai-message-icon m-1' icon={faRobot} />
			</Col>
			<Col xs={10}>
				<Row className="chatbot--ai-message">
					<Col xs={12} md={2} className='align-self-center'>
						<PlayAudio
							audioBuffer={audioBuffer}
							isThinking={isThinking}
							showConfirmation={showConfirmation}
							autoplay={autoplay}
						/>
					</Col>
					<Col xs={12} md={10}>
						<div>{formattedMessage}</div>
					</Col>
				</Row>
			</Col>
		</Row>

	)
}

export default AIMessage