import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import {
	getExerciseList, getExerciseBaseLib, getExerciseEditList, getCommunityExercises, resetSearch
} from 'reducers/exerciseListSlice'
import { changeAdultLibrary } from 'reducers/previousPageSlice'
import { getLanguageCode } from 'utils/helpers'

import { Dispatch } from 'types/Types'
import { AdminUser } from 'types/UserTypes'

const LibraryUserCategorySelect = () => {
	const { t } = useTranslation()
	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)
	const lang = useAppSelector(state => state.previousPage.libraryLanguage)
	const user = useAppSelector(state => state.user)
	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useDispatch<Dispatch>()

	/**
	 * Function to handle changing the user category of the exercise library
	 * Show confirm modal if in assign-mode and changing to a another language than what is currenlty set.
	 * @param {boolean} adultLibrary - the user category that the user is changing to
	 */
	const handleLibraryChange = (adultLibrary: boolean) => {
		dispatch(resetSearch())
		dispatch(changeAdultLibrary(adultLibrary))
		const langCode = getLanguageCode(lang)
		dispatch(getExerciseEditList({ user: user.data as AdminUser, langCode, adultLibrary }))
		dispatch(getExerciseList({user, langCode, adultLibrary}))
		dispatch(getExerciseBaseLib({user, langCode, adultLibrary}))
		dispatch(getCommunityExercises({user, langCode, adultLibrary}))
	}

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<Dropdown show={isOpen} onToggle={toggleDropdown}>
				<Dropdown.Toggle className='exercise-library--secondary-button' variant='outline-secondary'
					data-cy='exercise_library_user_category_select_button'>
					{t(`edit_exercise.library_user_category_select.${adultLibrary ? 'adult' : 'child'}`)}
					<KeyboardArrowDownIcon />
				</Dropdown.Toggle>
				<Dropdown.Menu data-cy='exercise_library_user_category_select_options'>
					<Dropdown.Header>{t('edit_exercise.library_user_category_select.select_title')}</Dropdown.Header>
					<Dropdown.Item
						onClick={() => handleLibraryChange(false)}
					>
						{t('edit_exercise.library_user_category_select.child')}
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => handleLibraryChange(true)}
						data-cy='exercise_library_user_category_select_adult'
					>
						{t('edit_exercise.library_user_category_select.adult')}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

export default LibraryUserCategorySelect