import { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { changeCookieConsent } from 'reducers/appStateSlice'

import 'pages/CookiesPage/CookiesPage.css'

const Consent = () => {
    const [show, setShow] = useState(true)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleResponse = (selection) => {   
        dispatch(changeCookieConsent(selection))
        setShow(false)
    }

    return (
        <Modal
            className='consent--modal'
            show={show}
            onHide={() => handleResponse(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('consent.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('consent.body')}
                <br />                
                <Link to='/cookies' className='cookies--link'>{t('consent.link')}</Link>
            </Modal.Body>            
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleResponse(false)}>{t('consent.reject')}</Button>
                <Button variant="primary" onClick={() => handleResponse(true)}>{t('consent.accept')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Consent