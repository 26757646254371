import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { changePatientPageTab } from 'reducers/previousPageSlice'

import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'


const PatientEditPageTabs = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const tabIndex = useAppSelector(state => state.previousPage.patientPageTab)


	return (
		<Tabs
			defaultActiveKey={tabIndex}
			className='mb-3'
			onSelect={(idx) => dispatch(changePatientPageTab(idx!))}
			data-cy='patient_edit_page_tabs'
		>
			<Tab eventKey='0' title={t('edit_patient.exercises_title')}/>
			<Tab eventKey='1' title={t('edit_patient.statistics_title')}/>
			<Tab eventKey='2'
				title={
					<span>
						{t('edit_patient.settings.subtitle')}
						<SettingsRoundedIcon className='mx-2 pb-1'/>
					</span>
				}
			/>
		</Tabs>
	)
}

export default PatientEditPageTabs