import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'

import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import ExerciseImage from 'components/ExerciseImage'

import { EXERCISE_PROGRESS_STATUS_NEXT } from 'utils/config'
import { getQuestionLengthClass } from 'utils/helpers'

interface Props {
    questionText: string,
    questionAudio: number[]
}

const ExerciseTextAndAudioPrompt = ({ questionText, questionAudio }: Props) => {
	const hasImage = useAppSelector(state => state.currentExercise.exercise.settings.hasImage)
    const status = useAppSelector(state => state.currentExercise.stats.status)

	return (
        <Row className={`justify-content-center flex-grow-1`}>
            <Col className='align-self-center exercise--div-question'
                xs={12} sm={status === EXERCISE_PROGRESS_STATUS_NEXT || !hasImage ? 12 : 5}
            >
                <Row xs={12} className='justify-content-center align-items-center flex-direction-column'>
                    <Col className='align-self-center' xs={12} md={12}>
                        {questionText.length > 0
                            ? <AudioPlayer
                                buffer={questionAudio}
                                large={false}
                                text={true}
                            />
                            :<></>
                        }
                    </Col>
                    <Col 
                        xs={12} md={12}
                        className={`align-self-center my-3
                        exercise--question-text-${getQuestionLengthClass(questionText)}`}
                        data-cy='exercise_question_col'
                    >
                            {questionText}
                    </Col>
                </Row>
            </Col>
            {hasImage
                ? <Col
                    className="align-self-center" data-cy='exercise_image_col'
                    xs={12} sm={status === EXERCISE_PROGRESS_STATUS_NEXT ? 12 : 7}
                >
                    <ExerciseImage />
                </Col>
                :<></>
            }
        </Row>
	)
}

export default ExerciseTextAndAudioPrompt