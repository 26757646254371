import { useEffect } from 'react'
import React, { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { userIsAdmin, userIsSlp } from 'utils/helpers'

import DashboardCard from 'components/DashboardCard'
import HubspotFeedbackForm from 'components/HubspotFeedbackForm'
import { changePreviousPage } from 'reducers/previousPageSlice'

const AdminDashboardPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useAppSelector(state => state.user)

	useEffect(() => {
		dispatch(changePreviousPage('/dashboard'))
	}, [])

	return (

		<Container>
			<div className='exerciseListPage--card-group'>
				<DashboardCard
					title={`📚 ${t('navigation.edit_exercise')}`}
					text={t('navigation.dashboard.exercises_subtitle')}
					link='/edit_exercises'
				/>
				<DashboardCard
					title={`➕ ${t('navigation.create_exercise')}`}
					text={t('navigation.dashboard.create_exercise_subtitle')}
					link='/create'
				/>
				{ userIsAdmin(user.data) ?
					<>
						<DashboardCard
							title={`🌄 ${t('navigation.images')}`}
							text={t('navigation.dashboard.images_subtitle_admin')}
							link='/images'
						/>
						<DashboardCard
							title={`👥 ${t('navigation.all_users')}`}
							text={t('navigation.dashboard.users_subtitle')}
							link='/users'
						/>
					</>
					: <></>
				}
				{ userIsSlp(user.data) ?
					<>
						<DashboardCard
							title={`👥 ${t('navigation.patient_list')}`}
							text={t('navigation.dashboard.patients_subtitle')}
							link='/patients'
						/>
						<DashboardCard
							title={`➕ ${t('navigation.add_patient')}`}
							text={t('navigation.dashboard.add_patient_subtitle')}
							link='/create_user'
						/>
						<DashboardCard
							title={`🌄 ${t('navigation.images')}`}
							text={t('navigation.dashboard.images_subtitle_slp')}
							link='/images'
						/>
						<HubspotFeedbackForm />
					</>
					: <></>
				}
			</div>
		</Container>
	)
}

export default AdminDashboardPage