import {useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'

import { getPage, clearIds, clearPageNr, setSearchTerm, setSearchCategory } from 'reducers/imageSlice'
import ImageCard from 'components/ImageCard'
import ImageEditCard from 'components/ImageEditCard'

import './ImagesPage.css'
import ImageSearch from 'components/ImageSearch'
import ImagePagination from 'components/ImagePagination'
import { getLanguageCode} from 'utils/helpers'
import {userIsAdmin} from 'utils/helpers'

const Images = () => {
	const { t,i18n } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const currentEditImage = useSelector(state => state.image.currentEditImage)
	const images = useSelector(state => state.image.images)
	const [langCode, setLangCode] = useState(getLanguageCode(i18n.language))
	const user = useSelector(state => state.user)

	useEffect(() => {
		setLangCode(getLanguageCode(i18n.language))
	}, [t])

	useEffect(() => {
		dispatch(setSearchCategory('all'))
		dispatch(setSearchTerm(''))
		dispatch(clearIds())
		dispatch(clearPageNr())
		dispatch(getPage({
			nextPageBool: false,
			searchCategory: 'all',
			searchTerms: '',
			langCode:getLanguageCode(i18n.language)}))
	}, [langCode])

	const showAddImagePage = () => {
		navigate('/add_image')
	}

	return (
		<Container>
			<h1>{t('images.title')}</h1>
			{userIsAdmin(user.data)
				? <Button className='mb-2' onClick={showAddImagePage} data-cy='add-image-nav-button'>
					<FontAwesomeIcon icon={faAdd}/>
					<span className='mx-2'>{t('add_image.add_new_button')}</span>
				</Button>
				: <></>
			}
			<ImageSearch />
			<ImagePagination />
			<div className='images--container'>
				{images.map(image => {
					if (image.id === currentEditImage.id) {
						return (
							<ImageEditCard
								key={image.id}
							/>
						)
					} else {
						return (
							<ImageCard
								image={image}
								key={image.id}
								langCode={langCode}
							/>
						)
					}
				})}
			</div>
		</Container>
	)
}

export default Images