import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'stateHandling/hooks'
import { Alert, Container, Button, Row, Col, Form, InputGroup, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons'

import {
	getExerciseEditList,
	getExerciseList,
	resetSearch,
	getExerciseBaseLib,
	getCommunityExercises,
	setSearchTerm
} from 'reducers/exerciseListSlice'
import { changePreviousPage, changeAdultLibrary } from 'reducers/previousPageSlice'

import { getLanguageCode, filterExercises } from 'utils/helpers'

import Loading from 'components/Loading'
import ExerciseLibraryFilter from 'components/ExerciseFilter/ExerciseLibraryFilter'
import ExerciseLibraryTabs from 'components/ExerciseLibraryTabs'
import LibraryLanguageSelect from 'components/LibraryLanguageSelect/LibraryLanguageSelect'
import LibraryUserCategorySelect from 'components/LibraryUserCategorySelect'
import ExerciseLibrarySort from 'components/ExerciseLibrarySort/ExerciseLibrarySort'
import ExercisesWrapper from 'components/ExercisesWrapper/ExercisesWrapper'

import 'pages/ExercisePage/ExercisePage.css'
import './EditExerciseListPage.css'

import { Dispatch } from 'types/Types'
import { AdminUser } from 'types/UserTypes'
import { LibraryExercise } from 'types/ExerciseTypes'

const EditExerciseListPage = () => {
	const { t, i18n } = useTranslation()
	const { patientId } = useParams()
	const editExercises = useAppSelector(state => state.exerciseList.editExercises)
	// const exercises = useAppSelector(state => state.exerciseList.exercises)
	const baseLibExercises = useAppSelector(state => state.exerciseList.baseLibExercises)
	const communityExercises = useAppSelector(state => state.exerciseList.communityExercises)
	const loading = useAppSelector(state => state.appState.loading)
	const user = useAppSelector(state => state.user)
	const search = useAppSelector(state => state.exerciseList.search)
	const createMessage = useAppSelector(state => state.newOrEditedExercise.status)
	const previousPage = useAppSelector(state => state.previousPage.path)
	const tabIndex = useAppSelector(state => state.previousPage.libraryTab)
	const cardSort = useAppSelector(state => state.previousPage.cardSort)
	const lang = useAppSelector(state => state.previousPage.libraryLanguage)
	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)
	const patient = useAppSelector(state => state.currentPatient)

	const [filteredEditExercises, setFilteredEditExercises] = useState(editExercises as LibraryExercise[])
	const [filteredCommunityExercises, setFilteredCommunityExercises] = useState(communityExercises as LibraryExercise[])
	const [filteredBaseLibExercises, setFilteredBaseLibExercises] = useState(baseLibExercises as LibraryExercise[])
	const [exercisesToShow, setExercisesToShow] = useState(baseLibExercises as LibraryExercise[])
	const [distinctExercises, setDistinctExercises] = useState([] as LibraryExercise[])
	const [showSearchBar, setShowSearchBar] = useState(false)

	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()

	/* 
	* Set the previousPage attribute if it's not set
	*/
	useEffect(() => {
		if (previousPage === '/') {
			dispatch(changePreviousPage('/edit_exercises'))
		}
	}, [])

	/* 
	* Change adultLibrary property if currentPatient is changed
	*/
	useEffect(() => {
		if (patientId && patient.id) {
			dispatch(changeAdultLibrary(patient.isAdult))
		}
	}, [patient])

	useEffect(() => {
		const langCode = getLanguageCode(lang)
		dispatch(getExerciseEditList({ user: user.data as AdminUser, langCode, adultLibrary }))
		dispatch(getExerciseList({ user, langCode, adultLibrary }))
		dispatch(getExerciseBaseLib({ user, langCode, adultLibrary }))
		dispatch(getCommunityExercises({ user, langCode, adultLibrary }))
		if (previousPage !== '/edit_exercises') {
			dispatch(resetSearch())
		}
		if (!patientId) {
			dispatch(changePreviousPage('/edit_exercises'))
		}
	}, [user, adultLibrary])

	useEffect(() => {
		setFilteredEditExercises(filterExercises(editExercises, search, adultLibrary, cardSort))
		setFilteredCommunityExercises(filterExercises(communityExercises, search, adultLibrary, cardSort))
		setFilteredBaseLibExercises(filterExercises(baseLibExercises, search, adultLibrary, cardSort))
	}, [search, cardSort, editExercises, communityExercises, baseLibExercises])

	useEffect(() => {
		if (tabIndex === '0') {
			setExercisesToShow(filteredBaseLibExercises)
		} else if (tabIndex === '1') {
			setExercisesToShow(filteredEditExercises)
		} else {
			setExercisesToShow(filteredCommunityExercises)
		}
	}, [tabIndex, filteredBaseLibExercises, filteredEditExercises, filteredCommunityExercises])

	/**
     * Get the exercise cards that should be visible
	 * If updating so that each exercise is its own object, this function can be removed
     */
	useEffect(() => {
		const tempExercises = [] as LibraryExercise[]
		exercisesToShow.forEach(exercise => {
			if (exercise.useReading && (search.type.includes('read') || search.type.includes('all'))) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'read'
				tempExercises.push(tempExercise)
			} if (exercise.useListening && (search.type.includes('listen') || search.type.includes('all'))) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'listen'
				tempExercises.push(tempExercise)
			} if (exercise.useSpeaking && (search.type.includes('speak') || search.type.includes('all'))) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'speak'
				tempExercises.push(tempExercise)
			} if (exercise.useWriting && (search.type.includes('write') || search.type.includes('all'))) {
				const tempExercise = {...exercise }
				tempExercise.renderType = 'write'
				tempExercises.push(tempExercise)
			}
		})
		setDistinctExercises(tempExercises)
	}, [exercisesToShow])

	const showAddExercise = () => {
		navigate('/create')
	}

	const handleSearchPageState = (searchTerms = '') => {
		dispatch(setSearchTerm(searchTerms))
	}

	const parseSearchTerm = str => {
		return str.replace(/[^\w\säöåÄÖÅ]/gi, ' ')
	}

	/**
     Handles searching for exercises 
    */
	const handlePageSearch = async event => {
		event.preventDefault()
		const searchTerms = parseSearchTerm(event.target.value)
		handleSearchPageState(searchTerms)
	}

	// Function that blocks enter event from having effect or propagating in nested component
	const blockFormSubmit = event => {
		event.preventDefault()
		event.stopPropagation()
	}

	return (
		<Container>
			{loading
				? <Loading />
				: <>
					<Row xs="auto">
						<Col className='flex-grow-1'>
							<h2>
								{patientId
									? t('edit_exercise.assign_mode.page_title')
									: t('edit_exercise.page_title')
								}
							</h2>
						</Col>
						{showSearchBar
							? <Col className='float-end text-end'>
								<Form onSubmit={blockFormSubmit}>
									<Form.Group data-cy='exercise_list_title_filter'>
										<InputGroup>
											<InputGroup.Text>
												<FontAwesomeIcon icon={faSearch}/>
											</InputGroup.Text>
											<Form.Control
												type="search"
												placeholder={t('search_exercises.search_term_placeholder')}
												onChange={handlePageSearch}
												name='search'
												value={search.term}
												minLength={1}
												maxLength={200}
												autoFocus={true}
											/>
										</InputGroup>
									</Form.Group>
								</Form>
							</Col>
							: <Col className='float-end text-end'>
								<Button data-cy="search_exercise_button" variant='outline-secondary'
									className='exercise-library--secondary-button mb-2 mx-3'
									onClick={() => setShowSearchBar(!showSearchBar)}
								>
									<FontAwesomeIcon icon={faSearch}/>
								</Button>
							</Col>
						}
						{patientId
							? <></>
							: <Col className='float-end text-end'>
								<Button data-cy="create_new_exercise" className='mb-2' onClick={showAddExercise}>
									<FontAwesomeIcon icon={faAdd}/>
									<span className='mx-2'>{t('create_exercise.create_button')}</span>
								</Button>
							</Col>
						}
					</Row>
					<hr />
					<ExerciseLibraryTabs/>
					<Row xs='auto'>
						<Col className='flex-grow-1' xs={6} sm={8} data-cy='exercise_library_tab_subtitle'>
							<p>{t(`edit_exercise.tabs.${tabIndex}.subtitle`)}</p>
						</Col>
						{patientId
							? <></>
							: <Col className='float-end text-end mb-4'>
								<LibraryUserCategorySelect />
							</Col>
						}
						<Col className='float-end text-end mb-4'>
							<LibraryLanguageSelect
								assign={patientId ? true : false}
							/>
						</Col>
					</Row>
					{createMessage.showExerciseMessage
						? <Alert variant={createMessage.exerciseMessageVariant}>{createMessage.exerciseMessage}</Alert>
						: <></>
					}
					{createMessage.showAudioMessage
						? <Alert variant='warning'>{createMessage.showAudioMessage}</Alert>
						: <></>
					}
					<ExerciseLibraryFilter />
					<ExerciseLibrarySort
						numCards={distinctExercises.length}
					/>
					<ExercisesWrapper
						distinctExercises={distinctExercises}
						baseLib={tabIndex === '0'}
						assign={patientId ? true : false }
						patientId={patientId ? patientId : ''}
					/>
				</>
			}
		</Container>
	)
}

export default EditExerciseListPage