/** 
 * References: 
 * https://medium.com/@atulbanwar/efficient-polling-in-react-5f8c51c8fb1a
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API 
*/
import { useEffect, useState } from 'react'

export function usePageVisibility() {
	const [isPageVisible, setIsPageVisible] = useState(!document.hidden)

	useEffect(() => {
		const handleVisibilityChange = () => {
			setIsPageVisible(!document.hidden)
		}

		document.addEventListener('visibilitychange', handleVisibilityChange)

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [])

	return isPageVisible
}