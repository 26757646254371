import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Image, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { useDispatch } from 'react-redux'
import { useAppSelector } from 'stateHandling/hooks'
import { changeExerciseLoading } from 'reducers/currentExerciseSlice'
import { getExerciseList, changeFilteredExercises, changeExerciseAudio } from 'reducers/exerciseListSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import {getPatientStatistics} from 'reducers/currentPatientSlice'
import { getLanguageCode, userWithAssignedExercises, parseExerciseAudio } from 'utils/helpers'
import { transferAWSIds, listenSocket, disconnectSocket, initSocketConnection } from 'services/socket'

import ExerciseMenu from 'components/ExerciseMenu'
import Loading from 'components/Loading'
import LanguageSwitcher from 'components/LanguageSwitcher'
import ExerciseCard from 'components/ExerciseCard/ExerciseCard'
import LogOutButton from 'components/LogOutButton'
import PatientTotalProgressBar from 'components/PatientTotalProgressBar'

import { Dispatch } from 'types/Types'
import dialogLogo from 'dialog_logo_inverted_text_purple.png'
import './ExerciseListPage.css'

const ExerciseListPage = () => {
	const { i18n } = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()
	
	const filteredExercises = useAppSelector(state => state.exerciseList.filteredExercises)
	const loading = useAppSelector(state => state.appState.loading)
	const exerciseListLoading = useAppSelector(state => state.exerciseList.loading)
	const user = useAppSelector(state => state.user)
	const filterType = useAppSelector(state => state.exerciseList.filterType)
	const exercises = useAppSelector(state => state.exerciseList.exercises)
	const exerciseAudio = useAppSelector(state => state.exerciseList.exerciseAudio)

	/**
     * Start an exercise and set loading state
     * @param {string} id - id of exercise
	 * @param {string} answerFormat - answer format of exercise
     * @returns {void}
     */
	const startExercisePatient = (id, answerFormat) => () => {
		dispatch(changeExerciseLoading(true))
		if (answerFormat === 'listen' || answerFormat === 'read') {
			navigate(`/exercise/comprehension/${id}/${answerFormat}`)
		} else {
			navigate(`/exercise/production/${id}/${answerFormat}`)
		}
	}

	useEffect(() => {
		const langCode = getLanguageCode(i18n.language)
		dispatch(getExerciseList({user, langCode, adultLibrary: false}))
		dispatch(changePreviousPage('/'))
	}, [user.status.authenticated])

	/**
	* Update the current exercises based on updates on the filterType
	*/
	useEffect(() => {
		if (filterType === 'all') {
			dispatch(changeFilteredExercises(exercises))
		} else if (filterType === 'speak' || filterType === 'write') {
			const filtered = exercises.filter(exercise => exercise.useSpeaking || exercise.useWriting)
			dispatch(changeFilteredExercises(filtered))
		} else if (filterType === 'read' || filterType === 'listen') {
			const filtered = exercises.filter(exercise => exercise.useReading || exercise.useListening)
			dispatch(changeFilteredExercises(filtered))
		}
	}, [filterType, exercises])

	/**
	* Get the statistics, when the filtered exercises are fetched
	*/
	useEffect(() => {
		if (filteredExercises.length > 0) {
			dispatch(getPatientStatistics(user.data.cognitoId))
		}
	}, [filteredExercises])


	useEffect(() => {
		try {
			initSocketConnection()
			return () => disconnectSocket()
		} catch (exception) {
			console.error(exception)
		}
	}, [])

	useEffect(() => {
		if (exercises.length > 0) {
			listenSocket(async (err, data) => {
				try {
					if (data.type === 'audioBuffer') {
						const id = Object.keys(data.audioData)[0]
						if (!(id in exerciseAudio)) {
							const exerciseAudio = parseExerciseAudio(id, data.audioData[id])
							dispatch(changeExerciseAudio(exerciseAudio))
						}
					}
				} catch (exception) {
					console.error(exception)
				}
			})
			getAudioData() // TODO: check so that this don't get called too many times..
		}
	}, [exercises])

	/**
	 * Get the audio data by sending the AWS ids
	 * @returns { void }
	 */
	const getAudioData = () => {
		try {
			for (const e of exercises) {
				const audioObject = {}
				audioObject[e.id] = [e.title.audio, e.title.slowAudio, e.description.slp.audio, e.description.slp.slowAudio, e.description.patient.audio, e.description.patient.slowAudio]
				transferAWSIds(audioObject)
			}
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
   * Renders the correct cards given the input
   * @returns {JSX.element} Selected component
   */
	const getCards = (exercise, i, numQuestions) => {
		return (
			<>
				{ exercise.useSpeaking && filterType !== 'write'
					? <ExerciseCard
						key={`${exercise.id}_speak`}
						exercise={exercise}
						clickEvent={startExercisePatient(exercise.id, 'speak')}
						numQuestions={numQuestions}
						answerFormat={'speak'}
						idx={i+1}
					/>
					: <></>
				}
				{ exercise.useWriting && filterType !== 'speak'

					? <ExerciseCard
						key={`${exercise.id}_write`}
						exercise={exercise}
						clickEvent={startExercisePatient(exercise.id, 'write')}
						numQuestions={numQuestions}
						answerFormat={'write'}
						idx={i+1}
					/>
					: <></>
				}
				{ exercise.useListening && filterType !== 'read'
					? <ExerciseCard
						key={`${exercise.id}_listen`}
						exercise={exercise}
						clickEvent={startExercisePatient(exercise.id, 'listen')}
						numQuestions={numQuestions}
						answerFormat={'listen'}
						idx={i+1}
					/>
					: <></>
				}
				{ exercise.useReading && filterType !== 'listen'
					? <ExerciseCard
						key={`${exercise.id}_read`}
						exercise={exercise}
						clickEvent={startExercisePatient(exercise.id, 'read')}
						numQuestions={numQuestions}
						answerFormat={'read'}
						idx={i+1}
					/>
					: <></>
				}
			</>
		)
	}

	return (
		<Container>
			<Row className='exerciseMenu--header'>
				<Col style={{flex: 100}}>
					<Image
						src={dialogLogo}
						width="130"
						className="d-inline"
						alt="Dialog logo"
					/>
				</Col>
				<Col>
					<div className='text-nowrap'>
						<h5 data-cy='username_display' className='exerciseMenu--patient-username my-0'>
							<FontAwesomeIcon icon={faUser} className='mx-2'/>
							{user.data.username}
						</h5>
					</div>
				</Col>
				{ !userWithAssignedExercises(user.data)
					? <Col className='exerciseMenu--languageSelector-wrapper'>
						<LanguageSwitcher />
					</Col>
					: <></>
				}
				<Col>
					<LogOutButton/>
				</Col>
			</Row>

			{loading || exerciseListLoading
				? <Loading />
				: <>
					<ExerciseMenu />
					<br />
					{ filterType === 'all'
						? <PatientTotalProgressBar />
						: <></>
					}
					<div className='exerciseListPage--card-group mb-3'>
						{filteredExercises.map((exercise, i) => {
							const numQuestions = exercise.questions.length
							return <Fragment key={exercise.id}>
								{getCards(exercise, i, numQuestions)}
							</Fragment>
						})}
					</div>
				</>
			}
		</Container>
	)
}

export default ExerciseListPage