import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComment, faPen, faHeadphones, faBook, faBrain } from '@fortawesome/free-solid-svg-icons'
import { Image, Row, Col, OverlayTrigger, Tooltip as ToolTipReact } from 'react-bootstrap'
import { filterIconColor,skillIconColor } from 'utils/helpers'
import PhonologyIcon from 'images/phonology.svg'
import GrammarIcon from 'images/grammar.svg'
import UnderstandingIcon from 'images/understanding.svg'
import VocabularyIcon from 'images/vocabulary.svg'

// Register the necessary components from Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title)

interface PieChartData {
  label: string;
  value: number;
}

interface Props {
  data: PieChartData[];
  isAdult: boolean;
}

const MyPieChart = ({ data, isAdult }: Props) => {
	const { t } = useTranslation()
	const labels = data.map((item) => item.label)
	const [titleString, setTitleString] = useState<string>('')

	const totalValue = data.reduce((sum, item) => sum + item.value, 0)
	const percentageValues = data.map((item) => Math.round((item.value / totalValue) * 100))

	/**
   * Get the correct icon, given the type
   * @param {string} type - event instance for onKeyDown event
   */
	const getTypeIcon = (type: string) => {
		if (type === 'write')
			return <FontAwesomeIcon className='px-1' style={{ color: filterIconColor['write'] }} icon={faPen} />
		if (type === 'speak')
			return <FontAwesomeIcon className='px-1' style={{ color: filterIconColor['speak'] }} icon={faComment} />
		if (type === 'read')
			return <FontAwesomeIcon className='px-1' style={{ color: filterIconColor['read'] }} icon={faBook} />
		if (type === 'listen')
			return <FontAwesomeIcon className='px-1' style={{ color: filterIconColor['listen'] }} icon={faHeadphones} />
		else return <></>
	}

	/**
   * Get the correct icon, given the skill
   * @param {string} type - event instance for onKeyDown event
   */
	const getSkillIcon = (skill: string) => {
		if (skill === 'phonology') return <Image src={PhonologyIcon} className='px-1' />
		if (skill === 'grammar') return <Image src={GrammarIcon} className='px-1' />
		if (skill === 'understanding') return <Image src={UnderstandingIcon} className='px-1' />
		if (skill === 'vocabulary') return <Image src={VocabularyIcon} className='px-1' />
		if (skill === 'pragmatics') return <FontAwesomeIcon className='px-1' style={{ color: '#808080' }} icon={faBrain} />
		else return <></>
	}

	useEffect(() => {
		if (isAdult) {
			setTitleString(t('patient_statistics.pie_chart.title_adult'))
		} else {
			setTitleString(t('patient_statistics.pie_chart.title_child'))
		}
	}, [])

	let backgroundColor: string[]
	let borderColor: string[]

	if (isAdult){
		backgroundColor = labels.map(label => filterIconColor[label])
		borderColor = labels.map(label => filterIconColor[label])
	}
	else {
		backgroundColor = labels.map(label => skillIconColor[label])
		borderColor = labels.map(label => skillIconColor[label])
	}

	const formattedLabels = labels.map(label => t(`patient_statistics.pie_chart.labels.${label}`))

	const pieChartData = {
		labels: formattedLabels,
		datasets: [
			{
				data: percentageValues,
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1,
			},
		],
	}

	// Define the options for the chart
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
				onClick: null, // Disable legend item click to toggle datasets visibility on and off, add back if we want to show more data in the same chart
				display: false
			},
			title: {
				display: false,
				position: 'bottom' as const, // Ensure 'position' is a valid Chart.js position
				text: titleString, // Set the title text using translation
				font: {
					family: 'Helvetica Neue, Helvetica, Arial, sans-serif',
					size: 18,
					weight: 'bold',
				},
				padding: {
					top: 20,
					bottom: 30,
				},
			},
			layout: {
				padding: {
					top: 20,
					bottom: 20,
					left: 20,
					right: 20
				}
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						const value = context.raw
						return `${value}%` // Append '%' to the value
					},
				},
			},
		},
	}

	return (
		<>
			<Row className="ms-4 mb-3 mt-3">
				{labels.map((label, index) => (
					<OverlayTrigger
						key={`type-tooltip-${label}`}
						trigger={['hover', 'focus']}
						overlay={
							<ToolTipReact id={`type-tooltip-${label}`}>
								{t(`patient_statistics.pie_chart.labels.${label}`)}
							</ToolTipReact>
						}>
						<Col key={index}>
							{isAdult ? getTypeIcon(label) : getSkillIcon(label)}
						</Col>
					</OverlayTrigger>
				))}
			</Row>
			<Pie data-cy='statistics_pie_chart' data={pieChartData} options={options as any} />
			<h6 data-cy='statistics_pie_chart_title' style={{ fontWeight: 'bold', marginBottom: '30px', marginTop: '20px', textAlign:'center' }}>{titleString}</h6>
		</>
	)
}

export default MyPieChart
