import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'

import AudioPlayer from 'components/AudioPlayer/AudioPlayer'
import ExerciseImage from 'components/ExerciseImage'

import { EXERCISE_PROGRESS_STATUS_NEXT } from 'utils/config'
import { DefaultAudioBuffer } from 'types/QuestionTypes'


const ExerciseAudioPrompt = () => {
    const { t } = useTranslation()
	const question = useAppSelector(state => state.currentExercise.currentQuestion)
	const currentAudioBuffer = useAppSelector(state => state.currentExercise.currentAudioBuffer) as DefaultAudioBuffer
    const status = useAppSelector(state => state.currentExercise.stats.status)
    const hasImage = useAppSelector(state => state.currentExercise.exercise.settings.hasImage)

	return (
        <Row className={`mt-2 justify-content-center flex-grow-1`}>
            {question.question!.text && question.question!.text.length > 0
                ? <Col className="align-self-center" xs={12} sm={status === EXERCISE_PROGRESS_STATUS_NEXT ? 12 : 3}>
                    <div className={`my-2 exercise--div-question ${!hasImage ? 'exercise--div-comp-listen-question-noimg': ''}`}>
                            <div className='exercise--div-comp-audio-subtitle'>
                                <AudioPlayer
                                    buffer={currentAudioBuffer.question}
                                    large={true}
                                    text={true}
                                />
                                <h5 className='mt-1'>{t('exercise.play_audio.subtitle')}</h5>
                            </div>
                    </div>
                </Col>
                :<></>
            }
            {hasImage
                ? <Col xs={12} sm={status === EXERCISE_PROGRESS_STATUS_NEXT ? 12 : 9} className="align-self-center">
                    <ExerciseImage />
                </Col>
                : <></>
            }
        </Row>
	)
}

export default ExerciseAudioPrompt