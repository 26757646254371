import { useTranslation } from 'react-i18next'
import { ButtonGroup, ToggleButton, Badge } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { changeExerciseFilterType } from 'reducers/exerciseListSlice'
import { userWithAssignedExercises } from 'utils/helpers'
import {
	EXERCISE_TYPES
} from 'utils/config'


const ExerciseMenu = () => {
	const { t } = useTranslation()
	const types = ['all'].concat(EXERCISE_TYPES)
	const filterTypeValue = useSelector(state => state.exerciseList.filterType)
	const user = useSelector(state => state.user)
	const exercises = useSelector(state => state.exerciseList.exercises)
	const dispatch = useDispatch()

	const handleChange = (event) => {
		const value = event.currentTarget.value
		dispatch(changeExerciseFilterType(value))
	}

	const getNumExercisesOfSubtype = (filter) => {
		let allExercises = exercises
		if (filter === 'speak') return exercises.filter(exercise => exercise.useSpeaking).length
		if (filter === 'write') return exercises.filter(exercise => exercise.useWriting).length
		if (filter === 'read') return exercises.filter(exercise => exercise.useReading).length
		if (filter === 'listen') return exercises.filter(exercise => exercise.useListening).length
		let numExercises = 0
		allExercises.forEach(exercise => {
			if (exercise.useWriting) numExercises++
			if (exercise.useSpeaking) numExercises++
			if (exercise.useReading) numExercises++
			if (exercise.useListening) numExercises++
		})
		return numExercises
	}

	return (
		<>
			{!userWithAssignedExercises(user.data) ?
				<ButtonGroup className='exerciseMenu--type-wrapper mb-2'>
					{types.map(type => {
						return (
							<ToggleButton
								key={type}
								id={type}
								type='radio'
								variant='secondary'
								name='type'
								className='text-nowrap'
								checked={filterTypeValue === type}
								value={type}
								onChange={handleChange}
							>
								{t(`create_exercise.settings.types.${type}`)}
								<Badge pill bg='light' text='dark' className="mx-1">
									{getNumExercisesOfSubtype(type)}
								</Badge>
							</ToggleButton>
						)
					})}
				</ButtonGroup>
				: <></>
			}
		</>
	)
}

export default ExerciseMenu