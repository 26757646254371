import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col } from 'react-bootstrap'
import { useAppSelector } from 'stateHandling/hooks'
import { AnswerColor } from 'types/QuestionTypes'
import { Answer } from 'types/AnswerTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

interface Props {
    alternatives: Answer[]
    answerColor: AnswerColor
    altOnClick: (answerObj: Answer, index: number) => void
}

const AnswerFormatYesNo = ({alternatives, answerColor, altOnClick }: Props) => {
	const { t } = useTranslation()
	const hasImage = useAppSelector(state => state.currentExercise.exercise.settings.hasImage)
	const [btnClass, setBtnClass] = useState('comprehension--alt-base comprehension--alt-btn-lg')

	useEffect(() => {
		if (hasImage) {
			setBtnClass('comprehension--alt-base comprehension--alt-btn-sm')
		}
	}, [])

	return (
		<>
			{alternatives.map((answerObj: Answer, i: number) => {
				return (
					<Col
						className='text-center' xs={6} sm={6} md={4} lg={3} xl={3}
						key={`${answerObj.answer.text}_${i}`}
					>
						<Button
							className={
								`text-nowrap m-2 py-2 px-4 ${btnClass} comprehension--alt-btn
								comprehension--alt-${answerColor[i]}`
							}
							variant='outline-dark'
							onClick={() => altOnClick(answerObj, i)}
						>
							<FontAwesomeIcon
								// Alternatives always saved as "yes" or "no" independent of language                            
								icon={answerObj.answer.text === 'yes' ? faThumbsUp : faThumbsDown}
								className={`${answerObj.answer.text === 'yes'
									? 'comprehension--success' : 'comprehension--fail'}`}
							/>
							{t(`exercise.${answerObj.answer.text}`)}
						</Button>
					</Col>
				)
			})}
		</>
	)
}

export default AnswerFormatYesNo