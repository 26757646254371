import { getUserSession } from 'utils/userSession'

/**
 * Prepare the header with the access token
 * @returns {Object} Formatted access token header
 */
export const getAuthHeader = async () => {
	const session = await getUserSession()
	if (session && session.accessToken.jwtToken) {
		return { 'x-access-token': session.accessToken.jwtToken }
	}
	return {}
}