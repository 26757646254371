import Spinner from 'react-bootstrap/Spinner'

function Loading() {
    return (
        <div className='exercisePage--loading-wrapper'>
            <Spinner className='exercisePage--loading-icon' animation="border" variant="primary" />
        </div>
    )
  }
  
  export default Loading