import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import userService from 'services/users'
import { useAppSelector } from 'stateHandling/hooks'
import { useDispatch } from 'react-redux'
import { resetCurrentPatient } from 'reducers/currentPatientSlice'

interface Props {
    showDeleteConfirmModal: any
    setShowDeleteConfirmModal: any
    changeUserMessage: any
}

const ConfirmDeleteModal = ({showDeleteConfirmModal, setShowDeleteConfirmModal, changeUserMessage}: Props) => {
	const { t, i18n } = useTranslation()
	const patient = useAppSelector(state => state.currentPatient)
	const user = useAppSelector(state => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const deletePatientAccount = async (patientId: string, userId: string) => {
		setShowDeleteConfirmModal(false)

		const result = await userService.deletePatient({ patientId, patientCognitoId: patient.cognitoId, userId })

		if (result) {
			dispatch(resetCurrentPatient())
			navigate('/patients')
			changeUserMessage('success', `${t('edit_patient.messages.delete_success')}: ${patient.username}`)
		} else {
			changeUserMessage('danger', `${t('edit_patient.messages.delete_failure')}: ${patient.username}`)
		}
	}

	return (
		<>
			<Modal data-cy='delete_patient_modal' show={showDeleteConfirmModal}>
				<Modal.Header>
					<Modal.Title>{t('edit_patient.delete_title')}: <i>{patient.username}</i>?</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{/* TODO: We might want to change the message below: delete_body. See translation file */}
					<p>{t('edit_patient.delete_body')}</p>
				</Modal.Body>

				<Modal.Footer>
					<Button data-cy='cancel_delete_patient_button' variant='secondary'
						onClick={() => setShowDeleteConfirmModal(false)}
					>
						{t('edit_patient.exercises.cancel')}
					</Button>
					<Button data-cy='confirm_delete_patient_button' variant='danger'
						onClick={() => deletePatientAccount(patient.id, user.data.id)}
					>
						{t('edit_patient.delete_account')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmDeleteModal