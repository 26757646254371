import { useEffect, useState } from 'react'
import Image from 'react-bootstrap/Image'
import { useSelector } from 'react-redux'
import { AWS_URL } from 'utils/config'
import ExerciseFeedback from 'components/ExerciseFeedback/ExerciseFeedback'

const ExerciseImage = () => {
	const [imageURL, setImageURL] = useState(null)
	const question = useSelector(state => state.currentExercise.currentQuestion)
	const status = useSelector(state => state.currentExercise.stats.status)
	const confidence = useSelector(state => state.currentExercise.stats.confidence)
	const hasImage = useSelector(state => state.currentExercise.exercise.settings.hasImage)
	const [currentStatus, setCurrentStatus] = useState(status)
	const [correct, setCorrect] = useState(null)
	const [imgClass, setImgClass] = useState('exercise--img')

	useEffect(() => {
		if (hasImage) {
			setImageURL(AWS_URL + question.imageURL)
		}
	}, [question])

	useEffect(() => {
		if (question.answerFormat === 'image') {
			setImgClass('exercise--img exercise--img-small')
		}
	}, [question])

	useEffect(() => {
		if (status === 'none') {
			setCorrect(null)
		} else if (status === 'correct') {
			setCorrect(true)
		} else if (status === 'incorrect') {
			setCorrect(false)
		}
		setCurrentStatus(status)
	}, [status])

	return (
		<>
			<div className='exercise--div-img mb-1'>
				<div className='exercise--img-wrapper'>
					<Image
						src={imageURL}
						className={`d-inline-block ${imgClass} mb-2`}
						alt=''
						data-cy='exercise_question_image'
						key={imageURL}
					/>
				</div>
			</div>
		</>
	)
}

export default ExerciseImage