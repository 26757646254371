import Form from 'react-bootstrap/Form'

const FilePicker = ({ value, handleChange }) => {
	return (
		<>
			<Form.Control
				onChange={handleChange}
				type='file'
				name='image'
				accept='.jpg, .jpeg, .png'
				encType='multipart/form-data'
				value={value}
				required
				data-cy='add-image-file-picker'
			/>
		</>
	)
}

export default FilePicker