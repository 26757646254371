import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp , faSpinner, faCirclePause } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { getAudioCopy, processAudio } from 'recorder/recorder'

import 'components/AudioPlayer/AudioPlayer.css'
import 'pages/ExercisePage/ExercisePage.css'

interface Props {
	buffer?:string | number[]
	large:boolean
	text: boolean
}

// expecting a single audio instance, currently in the form { type: Buffer, data: Array } which we then process in recorder.processAudio
const AudioPlayer = ({ buffer, large=false, text=true }:Props) => {
	const { t } = useTranslation()
	const [disabled, setDisabled] = useState(true)
	const [variant, setVariant] = useState('secondary')
	const AudioButton = useRef(null)
	const volumeIconInstance = <FontAwesomeIcon icon={faVolumeUp} className={`${large ? '' : 'audio--play-icon'}`}/>
	const loadingIconInstance = <FontAwesomeIcon icon={faSpinner} className='fa-spin' />
	const pauseIconInstance = <FontAwesomeIcon icon={faCirclePause} className='fs-4'/>
	const [currentIcon, setCurrentIcon] = useState(loadingIconInstance)
	const [audioContext, setAudioContext] = useState<AudioContext | null>(null)
	const [currentAudio, setCurrentAudio] = useState<AudioBufferSourceNode| null>(null)

	/**
	* Responsible for creating audioContext when component is mounted
	* And closing the audioContext when it is unmounted
	*/
	useEffect(() => {
		let audioContext:AudioContext
		try {
			audioContext = new AudioContext()
			setAudioContext(audioContext)
		} catch (exception) {
			console.error('AudioContext is not supported by the browser: ', exception)
		}
		return () => {
			if (audioContext) {
				audioContext.close()
			}
		}
	}, [])

	/**
     * Responsible for updating disabled state of the play audio button
     */
	useEffect(() => {
		if (buffer && buffer.length > 0) {
			setDisabled(false)
			if (large) {
				setVariant('primary')
			} else {
				setVariant('outline-primary')
			}
			setCurrentIcon(volumeIconInstance)
		} else {
			setDisabled(true)
			setVariant('outline-secondary')
			setCurrentIcon(loadingIconInstance)
		}
	}, [buffer])

	/**
     * Plays audio question or answer audio based on the given argument
     * Note: we need to create a copy of the original audio buffer to replay it
     * @returns {void}
     */
	const playAudio = async (event) => {
		event.stopPropagation()
		if (audioContext) {
			try {
				audioContext.resume() // I have moved this here, as it didn't have desired effect if set in processAudio

				if (currentAudio != null) {
					currentAudio.stop()
					setCurrentAudio(null)
					setCurrentIcon(volumeIconInstance)
					setVariant('outline-primary')
					return
				}

				const processedAudio = await processAudio(buffer, audioContext)
				const audioCopy = getAudioCopy(processedAudio, audioContext)
				audioCopy.start(0)
				setCurrentAudio(audioCopy)
				setCurrentIcon(pauseIconInstance)
				setVariant('secondary')

				audioCopy.onended = () => {
					setCurrentAudio(null)
					setCurrentIcon(volumeIconInstance)
					setVariant('outline-primary')
				}
				// audio.start(audioContext.currentTime, 0, 0.3) // for prompt we can set time in start call, 
				// also by modifying AudioBufferSource node we can adjust playback speed
				// https://developer.mozilla.org/en-US/docs/Web/API/AudioBufferSourceNode/playbackRate
			} catch (exception) {
				console.error(exception)
			}
		}
	}

	return (
		<Button ref={AudioButton} className={`text-nowrap ${large ? 'exercise--large-audio-btn' : 'inline-audio-button'}`}
			disabled={disabled} variant={variant} onClick={playAudio} data-cy='exercise_play_audio_button'>
			{currentIcon}
			{!large && text
				? <span className='exercise--audio-button-text mx-2'>
					{t('exercise.play_audio.listen')}
				</span>
				: <></>
			}
		</Button>
	)
}

export default AudioPlayer