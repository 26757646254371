import { io } from 'socket.io-client'
import { SOCKET_ENDPOINT } from '../utils/config.js'

// Resources:
// Socket.io (client): https://medium.com/google-cloud/building-a-client-side-web-app-which-streams-audio-from-a-browser-microphone-to-a-server-part-ii-df20ddb47d4e
// Good resource: https://deepinder.me/creating-a-real-time-chat-application-with-react-hooks-socket-io-and-nodejs

let socket

/**
 * Opens the socket connection to SOCKET_ENDPOINT
 */
export const initSocketConnection = () => {    
    try {
        socket = io(SOCKET_ENDPOINT,  {
            transports: ['websocket', 'polling']        
        })        
    } catch (exception) {
        console.error(exception)
    }
}

initSocketConnection()

/**
 * Disconnects socket connection to SOCKET_ENDPOINT
 */
export const disconnectSocket = () => {
    if (socket) socket.disconnect()
}

/**
 * Sends audio content and info to socket server for STT
 * @param {object} data 
 */
export const transferAudio = (data) => {
    socket.emit('audio', data)
}

/**
 * Sends an object to socket server for answer analysis for written answer
 * @param {object} data 
 */
export const transferText = (data) => {    
    socket.emit('text', data)
}

/**
 * Sends an object containing the AWS S3 ids of the audio buffers for retrieval
 * @param {object} data
 */
 export const transferAWSIds = (data) => {    
    socket.emit('awsId', data)    
}

/**
 * Listens the socket server
 * Currently listens sockets 'audioAsText', 'textAsAudio' and 'connect_failed'
 * @param {*} cb - Callback
 */
export const listenSocket = (cb) => {
    socket.on('audioAsText', data => {        
        return cb(null, data)
    })

    socket.on('textAnswerFeedback', data => {        
        return cb(null, data)
    })

    socket.on('textAsAudio', data => {
        return cb(null, data)
    })

    socket.on('audioBuffer', data => {
        return cb(null, data)
    })

    socket.on('connect_failed', () => {        
        return cb(null, null) // is this right?
    })

    // They do like this in the documentation -- have not noticed any difference
    // https://socket.io/how-to/use-with-react-hooks
    return () => {
        socket.off('audioAsText');
        socket.off('textAnswerFeedback');
        socket.off('textAsAudio');
        socket.off('connect_failed');
      };
}