import React from 'react'
import { useAppSelector } from 'stateHandling/hooks'
import { Row, Col } from 'react-bootstrap'

import ExerciseAudioPrompt from 'components/ExercisePrompt/ExerciseAudioPrompt/ExerciseAudioPrompt'
import ComprehensionAnswers from 'components/ComprehensionAnswers'

import { EXERCISE_PROGRESS_STATUS_NEXT } from 'utils/config'

const ExercisePromptListen = () => {
    const status = useAppSelector(state => state.currentExercise.stats.status)

	return (
        <>
            {status === EXERCISE_PROGRESS_STATUS_NEXT
                ? <Row className='exercise--div-question mb-2 flex-grow-1'>
                    <Col xs={12} sm={4} md={6} className='px-5 exercise--prompt-border'>
                        <ExerciseAudioPrompt />
                    </Col>
                    <Col xs={12} sm={8} md={6} className='px-5'>
                        <ComprehensionAnswers />
                    </Col>
                </Row>
                : <ExerciseAudioPrompt />
            }
        </>
	)
}

export default ExercisePromptListen