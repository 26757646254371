import { ExerciseStatsState, DBExercise } from 'types/ExerciseTypes'
import { DBStatistic } from 'types/StatisticTypes'
/**
 * Function to compile the statistics object for a question
 * @param { ExerciseStatsState } stats
 * @param { string } id
 * @param { boolean } correct 
 * @returns { QuestionStat } Object containing the statistic for a single question
 */
export const getQuestionStat = (stats: ExerciseStatsState, id: string, correct=true) => {
	const startTime = stats.answers.length > 0
		? new Date(stats.answers[stats.answers.length-1].end)
		: new Date(stats.timer.start)
	const endTime = new Date()
	return {
		questionId: id,
		correct,
		// userInput: '', // TODO add
		start: startTime.toISOString(),
		end: endTime.toISOString()
	}
}

/**
 * Function to compile the statistics object for an exercise 
 * @param { DBExercise } exercise
 * @param { string } cognitoId
 * @param { ExerciseStatsState } stats
 * @param { boolean } completed 
 * @returns { ExerciseStat } Object containing the statistic for a single question
 */
export const getExerciseStatistic = (exercise: DBExercise, cognitoId, stats, completed=true) => {
	const endTime = new Date()
	const data = {
		exerciseId: exercise.id,
		answerFormat: exercise.answerFormat,
		cognitoId: cognitoId,
		start: new Date(stats.timer.start).toISOString(),
		end: endTime.toISOString(),
		completed: completed,
		answers: stats.answers
	}
	return data
}

/**
 * Filters statistics data for a specific exercise id
 * @param {string} id - id of exercise
 * @returns {void}
 */
export const filterStatisticsByExerciseId = (exerciseId,statistics:DBStatistic[]) => {

	const tempFilteredPatientStatistics = Object.values(statistics).map(statistic => ({
		...statistic,
		attempts: statistic.attempts.filter(attempt =>
			attempt.exerciseId === exerciseId && attempt.completed
		)
	}))
		// To remove any already existing instances with only non-completed attempts
		.filter(statistic => statistic.attempts.length > 0)

	return tempFilteredPatientStatistics
}
