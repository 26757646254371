import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import './ExerciseLibraryCard.css'

interface Props {
 text: string
}

const ExpandableText = ({ text }: Props) => {
	const { t, i18n } = useTranslation()
	const [isExpanded, setIsExpanded] = useState(false)
	const [showExpand, setShowExpand] = useState(false)
	const textRef = useRef<HTMLDivElement>(null)
	const colRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const textElement = textRef.current
		const colElement = colRef.current
		if (textElement && colElement) {
			const colOffsetHeight = colElement.offsetHeight
			const textOffsetHeight = textElement.offsetHeight
			if (colOffsetHeight < textOffsetHeight) {
				setShowExpand(true)
			}
		}
	}, [text])

	const toggleExpand = () => {
		setIsExpanded(!isExpanded)
	}

	return (
		<Row xs='auto' className='align-items-center'>
			{!isExpanded
				? <>
					<Col xs={6} sm={i18n.language === 'fi' ? 6 : 7} lg={i18n.language === 'fi' ? 7 : 8}
						ref={colRef}
						className={`flex-grow-1 exerciseLibraryCard--truncated-text ${!showExpand ? 'mb-2 mt-1' :''}`}
						data-cy='exercise_library_card_description_truncated'
						style={{ maxHeight: isExpanded ? 'none' : '1.6em' }}
					>
						<span ref={textRef}>
							{text}
						</span>
					</Col>
					{showExpand
						? <Col xs={6} sm={i18n.language === 'fi' ? 6 : 5} lg={i18n.language === 'fi' ? 5 : 4}
							className='text-end p-0'
						>
							<Button variant='link' onClick={toggleExpand}
								className='exercise-library--filter-button text-nowrap'
								data-cy='exercise_library_card_show_description_button'>
								{t('exercise_library_card.show_more_button')}
								<KeyboardArrowDownIcon />
							</Button>
						</Col>
						: <></>
					}
				</>
				: <Col
					ref={colRef}
					data-cy='exercise_library_card_description_expanded'
					style={{ maxHeight: isExpanded ? 'none' : '1.6rem' }}
				>
					<span ref={textRef}>
						{text}
					</span>
					<Button variant='link' onClick={toggleExpand}
						className='mx-1 d-inline-block exercise-library--filter-button'
						data-cy='exercise_library_card_hide_description_button'>
						{t('exercise_library_card.show_less_button')}
						<KeyboardArrowUpIcon />
					</Button>
				</Col>
			}
		</Row>
	)
}

export default ExpandableText

