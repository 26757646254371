import React, { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'stateHandling/hooks'
import { faComment, faPen, faHeadphones, faBook, faQuestion } from '@fortawesome/free-solid-svg-icons'

import exerciseService from 'services/exercises'
import { changeAppLoading } from 'reducers/appStateSlice'
import { setEditExercise } from 'reducers/newOrEditedExerciseSlice'
import { changeExerciseLoading } from 'reducers/currentExerciseSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'

import ExerciseLibraryTable from 'components/ExerciseLibraryTable/ExerciseLibraryTable'
import ExerciseLibraryCardGroup from 'components/ExerciseLibraryCardGroup/ExerciseLibraryCardGroup'
import { DBStatistic } from 'types/StatisticTypes'
import { filterStatisticsByExerciseId } from 'utils/statisticsHelper'

import { Dispatch } from 'types/Types'
import { LibraryExercise } from 'types/ExerciseTypes'

interface Props {
	distinctExercises: LibraryExercise[]
    selectedToRemove: LibraryExercise[]
    onItemCheck: (event: ChangeEvent<HTMLInputElement>, exercise: LibraryExercise, renderType: string) => void
}

const AssignedExercisesWrapper = ({ distinctExercises, selectedToRemove, onItemCheck }: Props) => {
	const dispatch = useDispatch<Dispatch>()
	const navigate = useNavigate()
	const gridView = useAppSelector(state => state.previousPage.gridView)
	const patientId = useAppSelector(state => state.currentPatient.id)
	const [filteredPatientStatistics, setFilteredPatientStatistics] = useState<DBStatistic[]>([])
	const patientStatistics = useAppSelector(state => state.currentPatient.statistics)
	const [showStatistics, setShowStatistics] = useState<boolean>(false)

	/**
     * Opens the selected exercise in preview
     * @param {Number} id - Id of the selected exercise
	 * @param {String} renderType - type to be rendered
	 * @param {Boolean} baseLib - is the exercise a baselibrary or not
     * @returns {Void}
     */
	const viewExercise = (id, renderType, baseLib) => async () => {
		dispatch(changeAppLoading(true))
		const data = await exerciseService.getById(id)
		dispatch(setEditExercise(data))
		dispatch(changeAppLoading(false))
		dispatch(changePreviousPage(`/patients/${patientId}`))
		navigate(`/exercises/view/${id}/${renderType}/${baseLib}`)
	}

	/**
     * Start an exercise in patient view and set loading state
     * @param {string} id - id of exercise
	 * @param {string} answerFormat - answer format of exercise
     * @returns {void}
     */
	const startExerciseAsPatient = (id, answerFormat) => {
		dispatch(changeExerciseLoading(true))
		dispatch(changePreviousPage(`/patients/${patientId}`))
		if (answerFormat === 'listen' || answerFormat === 'read') {
			navigate(`/exercise/comprehension/${id}/${answerFormat}`)
		} else {
			navigate(`/exercise/production/${id}/${answerFormat}`)
		}
	}

	/**
     * Fetches statistics data for a specific exercise and toggles showing a modal
     * @param {string} id - id of exercise
     * @returns {void}
     */
	const toggleShowStatistics = (exerciseId) => {
		const exerciseStatistics = filterStatisticsByExerciseId(exerciseId,patientStatistics)
		setFilteredPatientStatistics(exerciseStatistics)
		setShowStatistics(true)
	}

	/**
     * Handles closing the statistics modal
     * @returns {void}
     */
	const handleCloseStatisticsModal = () => {
		setShowStatistics(false)
	}

	/**
	 * Returns the correct icon to go in the card header
	 * @returns { IconDefinition }  icon -- the correct fontawesone icon
	 */
	const getIcon = (answerFormat: string) => {
		let icon
		switch (answerFormat) {
		case 'speak':
			icon = faComment
			break
		case 'write':
			icon = faPen
			break
		case 'listen':
			icon = faHeadphones
			break
		case 'read':
			icon = faBook
			break
		default:
			icon = faQuestion
			console.error('Unknown answer format!')
		}
		return icon
	}

	return (
		<>
			{ gridView
				? <ExerciseLibraryCardGroup
					exercises={distinctExercises}
					baseLib={false}
					viewExercise={viewExercise}
					startExercise={startExerciseAsPatient}
					getIcon={getIcon}
					assign={true}
					selectedExercises={selectedToRemove}
					exerciseSelect={onItemCheck}
					showPlay={true}
					filteredPatientStatistics={filteredPatientStatistics}
					showStatistics={showStatistics}
					toggleShowStatistics={toggleShowStatistics}
					handleCloseStatisticsModal={handleCloseStatisticsModal}
				/>
				: <ExerciseLibraryTable
					exercises={distinctExercises}
					baseLib={false}
					viewExercise={viewExercise}
					startExercise={startExerciseAsPatient}
					getIcon={getIcon}
					assign={true}
					selectedExercises={selectedToRemove}
					exerciseSelect={onItemCheck}
					showPlay={true}
					filteredPatientStatistics={filteredPatientStatistics}
					showStatistics={showStatistics}
					toggleShowStatistics={toggleShowStatistics}
					handleCloseStatisticsModal={handleCloseStatisticsModal}
				/>
			}
		</>
	)
}

export default AssignedExercisesWrapper