import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { setScenario, getResponse, sendAudio } from 'services/chatbot'
import Chat from 'components/Chatbot/Chat'
import Feedback from 'components/Chatbot/Feedback'
import Footer from 'components/Chatbot/Footer'
import Loading from 'components/Loading'
import ContinuePrompt from 'components/Chatbot/ContinuePrompt'
import AudioProcessingConsentModal from 'components/AudioProcessingConsentModal'
import { resetCurrentScenario, changeLoading, changeMaxTurns, changeCurrentTurn } from 'reducers/chatbotSlice'
import { changePreviousPage } from 'reducers/previousPageSlice'
import timer from 'timer/index'

const ChatbotScenarioPage = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const promptLoading = useSelector(state => state.chatbot.loading)
	const useSpeech = useSelector(state => state.chatbot.scenarioSettings.useSpeech)
	const maxTurns = useSelector(state => state.chatbot.scenarioSettings.maxTurns)
	const currentTurn = useSelector(state => state.chatbot.scenarioSettings.currentTurn)
	const audioProcessingConsent = useSelector(state => state.appState.audioProcessingConsent)
	const [message, setMessage] = useState('')
	const [conversation, setConversation] = useState([])
	const [isThinking, setIsThinking] = useState(false)
	const [audioBuffer, setAudioBuffer] = useState(null)
	const [startTime, setStartTime] = useState(0)
	const [duration, setDuration] = useState(0)
	const [isFinished, setIsFinished] = useState(false)
	const [isContinuePrompt, setIsContinuePrompt] = useState(false)
	const [showConfirmation, setShowConfirmation] = useState(false)
	const [autoplay, setAutoplay] = useState(true)
	const [isConfirmed, setIsConfirmed] = useState(false)
	const [showConsentPopup, setShowConsentPopup] = useState(false)

	/**
     * Show the audioProcessingConsent modal if consent is not given
     */
	useEffect(() => {
		setShowConsentPopup(!audioProcessingConsent && useSpeech)
	}, [audioProcessingConsent])

	useEffect(() => {
		dispatch(changePreviousPage('/chatbot'))
	}, [])

	/**
   * Callback function in useEffect fetches the audio for the current scenario
   */
	useEffect(() => {
		const fetchAudio = async () => {
			const res = await setScenario(parseInt(id))
			setAudio(res)
			setConversation([ ...conversation, {role: 'AI', content: res.content} ])
			dispatch(changeLoading(false))
		}
		dispatch(changeLoading(true))
		fetchAudio()
		const date = new Date()
		setStartTime(date.toJSON())
	}, [])

	useEffect(() => {
		if (currentTurn >= maxTurns && isConfirmed) {
			setIsContinuePrompt(true)
		}
	}, [currentTurn])

	useEffect(() => {
		if (useSpeech && message) {
			if (isConfirmed) {
				handleSubmit()
			}
		}
	}, [message, isConfirmed])

	const handleContinueConversation = () => {
		dispatch(changeMaxTurns())
		setIsContinuePrompt(false)
	}

	const handleFinishConversation = () => {
		setIsFinished(true)
		const time = timer.getDuration(startTime)
		setDuration(timer.getTimeFormatted(time))
		setIsContinuePrompt(false)
	}

	/**
	   * Function handles message state whenever user input in the text area is updated
	   * @param {Event} event - event object
	   */
	const handleInput = (event) => {
		event.preventDefault()
		if (event.target.name === 'response')
			setMessage(event.target.value)
	}

	const handleCloseChat = async (event) => {
		const name = event.target.name
		if (name === 'home') {
			dispatch(resetCurrentScenario())
			navigate(`/chatbot`)
		}

		// Functionality for creating new scenarios
		// else if (name === 'newScenario') {      
		//   setIsNewScenario(true)
		// } else if (name === 'createScenario') {
		//   console.log('Trying to create the following scenario: ', scenarioDescription)      
		//   const res = await createScenario(scenarioDescription)
		//   setScenarioDescription({ title: '', description: '' })
		//   setIsNewScenario(false)      
		// }
	}

	const setAudio = (res) => {
		const audioData = atob(res.audio)
		const arrayBuffer = new Uint8Array(audioData.length)
		for (let i = 0; i < audioData.length; i++) {
			arrayBuffer[i] = audioData.charCodeAt(i)
		}
		setAudioBuffer(arrayBuffer)
	}

	/**
	   * Handles the event of user submitting the message
	   * First it updates the conversation state with user input
	   * Then the chatbot response is fetched from the server and the conversation state is updated again
	   */
	const handleSubmit = async () => {

		if (typeof message !== 'string'|| message.trim().length === 0) return
		dispatch(changeCurrentTurn())
		setIsThinking(true)

		try {
			if (message.length > 0) {
			// Shitty logic to render user message, then wait for AI answer - should be updated later after looking best practises for hook states    
				setConversation([...conversation, {role: 'USER', content: message}])
				setIsConfirmed(true)
				const res = await getResponse(message)
				const temp = [...conversation]
				temp.push({ role: 'USER', content: message })
				temp.push({ role: 'AI', content: res.content })
				setAudio(res)
				setConversation(temp)
				setMessage('')
			}
		} catch (exception) {
			console.error('Error: ', exception)
		} finally {
			setIsThinking(false)
		}
	}

	/**
	   * This could be handled in two steps: 
	   * 1. Send audio to backend - transcribe - display user message 
	   * 2. Send transcription to backend - generate chatbot output - display chatbot output
	   * @param {*} files 
	   */
	const handleAudioSubmit = async (formData) => {
		try {
			const res = await sendAudio(formData)
			setMessage(res)
			setShowConfirmation(true)
			setIsConfirmed(false)
		} catch (exception) {
			console.error('ERROR: ', exception)
		}
	}

	const handleConfirmation = (response) => {
		setIsConfirmed(response)
		setShowConfirmation(false)
		setAutoplay(response)
		if (!response) {
			setMessage('')
		}
	}

	const renderFeedback = () => {
		return (
			<>
				<Feedback
					duration={duration}
				/>
				<Footer
					message={message}
					isThinking={isThinking}
					isFinished={true}
					showConfirmation={showConfirmation}
					handleInput={handleInput}
					handleSubmit={handleSubmit}
					handleAudioSubmit={handleAudioSubmit}
					handleClick={handleCloseChat}
				/>
			</>
		)
	}

	const renderChat = () => {
		return (
			<>
				<Chat
					conversation={conversation}
					audioBuffer={audioBuffer}
					isThinking={isThinking}
					message={message}
					showConfirmation={showConfirmation}
					autoplay={autoplay}
					handleConfirmation={handleConfirmation}
					handleCloseChat={handleCloseChat}
				/>
				<Footer
					message={message}
					isThinking={isThinking}
					isFinished={isFinished}
					showConfirmation={showConfirmation}
					handleInput={handleInput}
					handleSubmit={handleSubmit}
					handleAudioSubmit={handleAudioSubmit}
					handleClick={handleCloseChat}
				/>
			</>
		)
	}

	const renderContinuePrompt = ()=> {
		return (
			<ContinuePrompt
				handleContinueConversation={handleContinueConversation}
				handleFinishConversation={handleFinishConversation}
			/>
		)
	}

	return (
		<Container fluid className='exercise--container h-100 min-vh-100'>
			{promptLoading
				? <Loading />
				: isFinished
					? renderFeedback()
					: isContinuePrompt
						? renderContinuePrompt()
						: renderChat()
			}
			{ showConsentPopup
				? <AudioProcessingConsentModal />
				: <></>
			}
		</Container>
	)
}

export default ChatbotScenarioPage