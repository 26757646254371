import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Col, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useAppSelector, useAppDispatch } from 'stateHandling/hooks'
import { tagColor } from 'utils/helpers'
import {
	EXERCISE_CATEGORIES,
	EXERCISE_SUBTYPES,
	EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY,
	EXERCISE_SUBTYPES_CHILDREN_GRAMMAR,
	EXERCISE_SUBTYPES_CHILDREN_VOCABULARY,
	EXERCISE_SUBTYPES_CHILDREN_PRAGMATICS
} from 'utils/config'
import { setSearchCategory, setSearchSubtype, resetSearch } from 'reducers/exerciseListSlice'
import FilterListIcon from '@mui/icons-material/FilterList'

interface FilterItem {
    type: string,
    selected: boolean
}

const ExerciseFilterDropdown = () => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation()
	const adultLibrary = useAppSelector(state => state.previousPage.adultLibrary)
	const search = useAppSelector(state => state.exerciseList.search)
	const skill = useAppSelector(state => state.exerciseList.search.skill)
	const [isOpen, setIsOpen] = useState(false)

	const [answerFormats, setAnswerFormats] = useState(EXERCISE_CATEGORIES.map(t => {
		const typeObj = { type: t, selected: false }
		return typeObj
	}))

	const getTagObj = (arr) => {
		return arr.map(t => {
			const typeObj = { type: t, selected: false }
			return typeObj
		})
	}

	const getTags = () => {
		if (adultLibrary) {
			return getTagObj(EXERCISE_SUBTYPES)
		} else {
			if (skill === 'phonology') {
				return EXERCISE_SUBTYPES_CHILDREN_PHONOLOGY.map(arr => getTagObj(arr))
			}
			if (skill === 'grammar') {
				return getTagObj(EXERCISE_SUBTYPES_CHILDREN_GRAMMAR)
			}
			if (skill === 'vocabulary') {
				return getTagObj(EXERCISE_SUBTYPES_CHILDREN_VOCABULARY)
			}
			if (skill === 'pragmatics') {
				return getTagObj(EXERCISE_SUBTYPES_CHILDREN_PRAGMATICS)
			}
			else {
				return []
			}
		}
	}

	const [tags, setTags] = useState(getTags())

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	useEffect(() => {
		setTags(getTags())
	}, [adultLibrary, skill])

	/**
     * Update the filters to match the search, if search cleared
     */
	useEffect(() => {
		answerFormats.forEach(format => {
			search.category.includes(format.type)
				? format.selected = true
				: format.selected = false
		})
		tags.forEach(tag => {
			search.subtype.includes(tag.type)
				? tag.selected = true
				: tag.selected = false
		})
	}, [search])

	/**
     * Event handler for checking an exercise format / category
     * @param {Event} event - event intance for onKeyDown event
     */
	const onFormatCheck = (event) => {
		const selectedFormat = event.target.value
		const tempAnswerFormats = answerFormats.map(item => {
			if (item.type === selectedFormat) {
				item.selected = event.target.checked
			}
			return item
		})
		setAnswerFormats(tempAnswerFormats)
		const selectedFormats = tempAnswerFormats.filter(item => item.selected).map(item => item.type)
		if (selectedFormats.length === 0) dispatch(setSearchCategory(['all']))
		else dispatch(setSearchCategory(selectedFormats))
	}

	const handlePhonologyTagCheck = (selectedTag: FilterItem) => {
		return tags.map(arr => {
			return arr.map(item => {
				if (item.type === selectedTag.type) {
					item.selected = !selectedTag.selected
				}
				return item
			})
		})
	}


	/**
     * Event handler for selecting an exericse tag
     * @param {Object} event - event intance for onKeyDown event
     */
	const onTagCheck = (selectedTag: FilterItem) => {
		let tempTags
		if (skill === 'phonology') {
			tempTags = handlePhonologyTagCheck(selectedTag)
		} else {
			tempTags = tags.map(item => {
				if (item.type === selectedTag.type) {
					item.selected = !selectedTag.selected
				}
				return item
			})
		}
		setTags(tempTags)
		const selectedTags = tempTags.flat().filter(item => item.selected).map(item => item.type)
		if (selectedTags.length === 0) dispatch(setSearchSubtype(['all']))
		else dispatch(setSearchSubtype(selectedTags))
	}

	const renderTags = () => {
		return (
			<>
				{adultLibrary || (!adultLibrary && skill !== 'all' && skill !== 'phonology' && skill !== 'understanding')
					? tags.map(item => {
						return (
							<Col key={item.type}
								className={
									`m-2 exercise-filter--select text-nowrap ${item.selected ? 'selected' : ''}
							exercise-filter--tag-filter-${tagColor[item.type] ? item.type : 'default'}`
								}
								data-cy={`tag_filter_${item.type}`}
								onClick={() => onTagCheck(item)}
							>
								{adultLibrary ? t(`create_exercise.settings.subtypes.${item.type}`) : t(`create_exercise.settings.subtypes.${item.type}`)}
							</Col>
						)})
					: skill === 'all'
						? <p data-cy='tag_filter_no_skill_text'>{t('create_exercise.settings.subtypes.no_skill')}</p>
						: skill === 'phonology'
							? tags.map((arr, i) => {
								if (!Array.isArray(arr)) return
								return (
									<>
										<Row xs='auto'>
											{arr.map((item) => {
												return (
													<>
														<Col key={item.type}
															className={
																`m-2 exercise-filter--select text-nowrap ${item.selected ? 'selected' : ''}
										exercise-filter--tag-filter-${tagColor[item.type] ? item.type : 'default'}`
															}
															data-cy={`tag_filter_${item.type}`}
															onClick={() => onTagCheck(item)}
														>
															{t(`create_exercise.settings.subtypes.${item.type}`)}
														</Col>
													</>
												)
											})}
										</Row>
										{i < 2 ? <Dropdown.Divider /> : <></>}
									</>
								)

							})
							: <p data-cy='tag_filter_no_tags_text'>{t('create_exercise.settings.subtypes.no_tags')}</p>
				}
			</>
		)
	}
	return (
		<Dropdown show={isOpen} onToggle={toggleDropdown} className='d-inline-block'>
			<Dropdown.Toggle className='exercise-library--secondary-button' variant="secondary-outline"
				data-cy='exercise_filter_open_button'>
				<FilterListIcon className='mx-1' />
				{t('search_exercises.filter_button')}
			</Dropdown.Toggle>

			<Dropdown.Menu className='w-100 p-3 exercise-filter--dropdown-menu' data-cy='exercise_filter_dropdown'>
				<h4 className='fs-6 text-center'>{t('search_exercises.filter_button')}</h4>
				<h4 className='fs-6'>{t('search_exercises.filter_tags_label')}</h4>
				<Row xs="auto" className={skill === 'phonology' ? 'flex-column' : ''}>
					{renderTags()}
				</Row>
				<Dropdown.Divider />
				<h4 className='fs-6 mt-4'>{t('search_exercises.search_category_label')}</h4>
				<Row>
					{answerFormats.map(item => {
						return (
							<Col key={item.type}>
								<Form.Check
									key={item.type}
									onChange={(event) => onFormatCheck(event)}
									checked={item.selected}
									type='checkbox'
									value={item.type}
									label={t(`create_exercise.settings.category.${item.type}`)}
									data-cy={`answerformat_filter_${item.type}`}
								/>
							</Col>
						)})
					}
				</Row>
				<Dropdown.Divider />
				<Row className='mt-4' xs='auto'>
					<Col className='flex-grow-1'>
						<Button onClick={() => dispatch(resetSearch())} variant='outline-secondary'
							className='exercise-library--secondary-button'
							data-cy='exercise_filter_dropdown_clear_button'>
							{t('search_exercises.search_reset')}
						</Button>
					</Col>
					<Col className='float-end text-end'>
						<Button data-cy='exercise_filter_dropdown_apply_button' onClick={() => setIsOpen(false)}>
							{t('search_exercises.show_exercises_button')}
						</Button>
					</Col>
				</Row>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default ExerciseFilterDropdown