//React
import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { userIsAdminOrSlp} from 'utils/helpers'

//State
import {
	nextQuestion,
	changeCurrentQuestion,
	clearCurrentExercise,
	changeAudioBuffer,
	increaseNumCorrect,
	changeAnswerStatus,
	changeStatus,
	changeConfidence,
	changeChosenAnswer,
	changeCurrentAlternatives,
	addQuestionStat,
	startExercise,
	setExerciseStartTime
} from 'reducers/currentExerciseSlice'
import { changeExerciseAudio } from 'reducers/exerciseListSlice'

//Types
import { AnswerColor, DefaultContentQuestionState } from 'types/QuestionTypes'
import { Answer } from 'types/AnswerTypes'
import { Dispatch } from 'types/Types'

//The rest
import { useAppSelector } from 'stateHandling/hooks'
import { transferAWSIds, listenSocket, disconnectSocket, initSocketConnection } from 'services/socket'
import statisticsService from 'services/statistics'
import { EXERCISE_LIFECYCLE, ENV, QUESTION_AUDIO_KEYS } from 'utils/config'
import { parseExerciseAudio, getExerciseAudioReqObject } from 'utils/helpers'
import { getExerciseData, getExerciseStartData } from 'utils/mixpanelHelper'
import { getExerciseStatistic, getQuestionStat } from 'utils/statisticsHelper'
// import audioStart from 'sounds/silent.mp3'

//Components
import AnswerFormatImage from 'components/ComprehensionAnswerFormats/AnswerFormatImage'
import AnswerFormatText from 'components/ComprehensionAnswerFormats/AnswerFormatText'
import AnswerFormatYesNo from 'components/ComprehensionAnswerFormats/AnswerFormatYesNo'
import ExercisePrompt from 'components/ExercisePrompt/ExercisePrompt'
import ExerciseDescription from 'components/ExerciseDescription/ExerciseDescription'
import ExerciseHeaderDescription from 'components/ExerciseHeader/ExerciseHeaderDescription'
import ExerciseHeader from 'components/ExerciseHeader/ExerciseHeader'
import StatusNext from 'components/ExerciseFooter/StatusNext'
import StatusDescription from 'components/ExerciseFooter/StatusDescription'
import Finished from 'components/Finished'
import Loading from 'components/Loading'

//CSS
import './ComprehensionExercisePage.css'

interface Props {
    consent: boolean
    mixpanel: any
}

const ComprehensionExercisePage = ({ consent, mixpanel }: Props) => {

	//Redux state
	const exercise = useAppSelector(state => state.currentExercise.exercise)
	const exerciseLength = useAppSelector(state => state.currentExercise.length)
	const currentQuestion = useAppSelector(state => state.currentExercise.currentQuestion) as DefaultContentQuestionState
	const currentAudioBuffer = useAppSelector(state => state.currentExercise.currentAudioBuffer)
	const stats = useAppSelector(state => state.currentExercise.stats)
	const exerciseLoading = useAppSelector(state => state.currentExercise.loading)
	const user = useAppSelector(state => state.user)
	const previousPagePath = useAppSelector(state => state.previousPage.path)
	const exerciseAudio = useAppSelector(state => state.exerciseList.exerciseAudio)

	//UseState
	const [answerColor, setAnswerColor] = useState<AnswerColor>({})
	const [randomizedAlternatives, setRandomizedAlternatives] = useState<Answer[]>([])
	const [audioObject, setAudioObject] = useState<{ question: string; answers: string[] } | null>(null)
	const [backendError, setBackendError] = useState(false)
	const [alternativesLoading, setAlternativesLoading] = useState(true)
	const [mixpanelStartEventSent, setMixpanelStartEventSent] = useState(false)

	const dispatch = useDispatch<Dispatch>()
	const { i18n } = useTranslation()
	const { id, format } = useParams()
	const navigate = useNavigate()

	/**
	 * Controls the alternativesLoading parameter
	 * so that alternatives only show when they have the filar order
	 */
	useEffect(() => {
		if (randomizedAlternatives.length > 0) {
			setAlternativesLoading(false)
		}
	}, [randomizedAlternatives])

	// Sets the start time when loading an exercise
	useEffect(() => {
		if (exercise.id) {
			const date = new Date()
			dispatch(setExerciseStartTime(date.toJSON()))
		}
	}, [exercise.id])

	/**
	 * Fetch the exercise audio when the exercise is loaded
	 */
	useEffect(() => {
		if (exercise.id && !(exercise.id in exerciseAudio)) {
			getExerciseAudioData()
		}
	}, [exercise.id])

	useEffect(() => {
		try {
			initSocketConnection()
			return () => disconnectSocket()
		} catch (exception) {
			console.error(exception)
		}
	}, [])

	useEffect(() => {
		if (exercise.id !== '' && !mixpanelStartEventSent){
			handleExerciseLifecycle('start')
			setMixpanelStartEventSent(true)
		}
	}, [exercise])

	// Randomize the order of the alternatives to be displayed for the user
	// But only if template is not listen and alternatives are not text
	useEffect(() => {
		if (currentQuestion.answerFormat !== 'text' || exercise.answerFormat === 'read') {
			const randomized = [...currentQuestion.answers].sort(() => Math.random() - 0.5)
			setRandomizedAlternatives(randomized)
		}
	}, [currentQuestion.answers, currentQuestion.answerFormat, exercise.answerFormat])

	//Set the audioObject to the same format as for expression exercises
	useEffect(() => {
		if (exercise.answerFormat === 'listen') {
			if (currentQuestion && currentQuestion.question && currentQuestion.answers) {
				let updatedAudioObject
				if (currentQuestion.answerFormat === 'text') {
					updatedAudioObject = {
						question: currentQuestion.question.audio,
						answers: currentQuestion.answers.map(answerObj => answerObj.answer.audio).flat()
					}
				} else {
					updatedAudioObject = {
						question: currentQuestion.question.audio
					}
				}
				setAudioObject(updatedAudioObject)
			}
		}
	}, [currentQuestion.id])

	useEffect(() => {
		listenSocket(async (err, data) => {
			try {
				if (data.type === 'audioBuffer') {
					const id = Object.keys(data.audioData)[0]
					if (!QUESTION_AUDIO_KEYS.includes(id)) { // If the audioBuffer is exercise audio data
						const exerciseAudio = parseExerciseAudio(id, data.audioData[id])
						dispatch(changeExerciseAudio(exerciseAudio))
					} else {
						dispatch(changeAudioBuffer(data.audioData))
					}
				}
			} catch (exception) {
				console.error(exception)
			}
		})
		if (audioObject) {
			getQuestionAudioData()
		}
	}, [audioObject])

	useEffect(() => {
		if (currentQuestion.answers.length > 0 && currentAudioBuffer && currentAudioBuffer.answers) {
			const updatedAlternatives = currentQuestion.answers.map((answerObj, i) => ({
				...answerObj,
				audio: currentAudioBuffer.answers[i],
			}))
			// Update state with audio buffer instead of audio id			
			dispatch(changeCurrentAlternatives(updatedAlternatives))
			// Randomize text alternatives for listen exercise
			const randomized = [...updatedAlternatives].sort(() => Math.random() - 0.5)
			setRandomizedAlternatives(randomized)
		}
	}, [currentAudioBuffer])

	//Set the audioObject to the same format as for expression exercises
	useEffect(() => {
		if (exercise.answerFormat === 'listen') {
			if (currentQuestion && currentQuestion.question &&
				currentQuestion.answers) {
				let updatedAudioObject
				// With new functionality, audio should not even be created for empty questions
				// But to make it work for questions created before this change, I added a check for empty string
				if (currentQuestion.question.audio &&
					currentQuestion.question.text.trim() !== '') {
					// Update audio for question
					updatedAudioObject = {
						...updatedAudioObject,
						question: currentQuestion.question.audio
					}
				}
				if (currentQuestion.answerFormat === 'text') {
					// Update audio for alternatives
					updatedAudioObject = {
						...updatedAudioObject,
						answers: currentQuestion.answers.map(answerObj => answerObj.answer.audio).flat()
					}
				}
				// Only set the audioObject if there is audio
				updatedAudioObject ? setAudioObject(updatedAudioObject) : null
			}
		}
	}, [currentQuestion.id])

	useEffect(() => {
		listenStatus()
	}, [stats.status])

	/**
     * Callback for fetching exercise data
     */
	const fetchExerciseData = useCallback(async () => {
		if (id && format) {
			dispatch(startExercise({id: id, answerFormat: format }))
		}
	}, [id])

	/**
    * Call the callback for fetching and storing the exercise data
    */
	useEffect(() => {
		try {
			fetchExerciseData()
		} catch (exception) {
			console.error(exception)
		}
	}, [fetchExerciseData])

	// TODO: check in staging if needed, if so add back
	// The following useEffect is a fix for Safari browser iOS,
	// which otherwise blocks playing audio automatically (i.e. correct and wrong sound)
	/* useEffect(() => {
		const audio = new Audio (audioStart)
		audio.play()
	}, []) */

	//When value of status hook changes update related states accordingly
	const listenStatus = () => {
		if (stats.status === 'correct') {
			dispatch(increaseNumCorrect())
			const questionStat = getQuestionStat(stats, currentQuestion.id)
			dispatch(addQuestionStat(questionStat))
			dispatch(changeStatus('next'))
		} else if (stats.status === 'incorrect') {
			const questionStat = getQuestionStat(stats, currentQuestion.id, false)
			dispatch(addQuestionStat(questionStat))
			dispatch(changeStatus('next'))
		} else if (stats.status === 'error') {
			dispatch(changeStatus('none'))
			setBackendError(true)
			setTimeout(() => {
				setBackendError(false)
			}, 10000)
		}
	}

	/**
     * Fetching Audio Buffers when a new question is loaded
    */
	const getQuestionAudioData = () => {
		try {
			transferAWSIds(audioObject)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
	 * Get the audio data for the exercise by sending the AWS ids
	 * @returns { void }
	 */
	const getExerciseAudioData = () => {
		try {
			const audioReqObject = getExerciseAudioReqObject(exercise)
			transferAWSIds(audioReqObject)
		} catch (exception) {
			console.error(exception)
		}
	}

	const handleQuestionState = () => {
		if (stats.index + 1 >= exerciseLength) {
			dispatch(changeStatus('finished'))
			return
		}
		const currentIndex = stats.index
		dispatch(changeCurrentQuestion(currentIndex + 1))
		dispatch(nextQuestion())
	}

	/**
	 * When skipping a question, still save the statistics (as incorrect)
	 */
	const handleSkip = () => {
		const questionStat = getQuestionStat(stats, currentQuestion.id, false)
		dispatch(addQuestionStat(questionStat))
		handleQuestionState()
	}

	const handleQuestion = () => {
		if (stats.status === 'next') {
			if (stats.index + 1 >= exerciseLength) {
				handleQuestionState()
				return
			}
			handleQuestionState()
			dispatch(changeStatus('none'))
			setAnswerColor({})
		} else if (stats.status === 'incorrect') {
			dispatch(changeStatus('next'))
		}
	}

	//When alternative is clicked
	const altOnClick = (altObj: Answer, index: number) => {
		setAnswerColor({})
		let updatedColor = ''
		dispatch(changeChosenAnswer(altObj))

		if (altObj.isCorrect) {
			dispatch(changeStatus('correct'))
			dispatch(changeAnswerStatus('correct'))
			dispatch(changeConfidence(1))
		} else {
			dispatch(changeConfidence(0))
			dispatch(changeStatus('incorrect'))
			dispatch(changeAnswerStatus('incorrect'))
			updatedColor = 'incorrect'
		}
		setAnswerColor(prevAnswerColor => ({
			...prevAnswerColor,
			[index]: updatedColor
		}))
	}

	/**
	 * Function to compile the statistics object for an exercise and post to DB
	 * @param { boolean } completed
	 * @returns { ExerciseStat } Object containing the statistic for a whole exercise
	 */
	const saveStatistics = async (completed=false) => {
		try {
			const data = getExerciseStatistic(exercise, user.data.cognitoId, stats, completed)
			await statisticsService.postStatistic(data)
		} catch (exception) {
			console.error(exception)
		}
	}

	/**
	* Function to collect exercise statistics, and send to Statistics and Mixpanel
	* If running cypress tests, data should not be collected
	* @param {string} type - type of user event, i.e. start, discontinue or complete
	*/
	const handleExerciseLifecycle = (type: string) => {
		if (!(ENV === 'cypress') && !userIsAdminOrSlp(user.data)) {
			if (type === 'discontinue' || type === 'complete') {
				saveStatistics(type === 'complete')
			}
			if (consent) {
				let data = {}
				if (type === 'start') data = getExerciseStartData(exercise, i18n.language, exerciseLength)
				else data = getExerciseData(exercise, i18n.language, exerciseLength, stats)
				data['event_type'] = type
				mixpanel.track(EXERCISE_LIFECYCLE, data)
			}
		}
		if (type === 'discontinue' || type === 'complete') {
			dispatch(clearCurrentExercise())
		}
	}

	const handleFinishedEvent = () => {
		handleExerciseLifecycle('complete')
		navigate(previousPagePath)
	}

	const renderPage = () => {
		if (stats.status === 'finished') {
			return <Finished handleFinished={handleFinishedEvent}/>
		} else if (stats.status === 'description') {
			return (
				<>
					<ExerciseHeaderDescription
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
					<ExerciseDescription />
					{exerciseFooter()}
				</>
			)
		} else {
			return (
				<>
					<ExerciseHeader
						handleSkip={handleSkip}
						handleExerciseLifecycle={handleExerciseLifecycle}
					/>
					<ExercisePrompt />
					{exerciseFooter()}
				</>
			)
		}
	}

	const answerSelection = () => {
		return (
			<>
				{ currentQuestion?.answerFormat === 'image' ?
					<Row className="comprehension--footer-bg">
						<AnswerFormatImage
							alternatives={randomizedAlternatives}
							answerColor={answerColor}
							altOnClick={altOnClick}
						/>
					</Row>
					:<></>
				}
				{ currentQuestion?.answerFormat === 'text' ?
					<Row className="comprehension--footer-bg">
						<AnswerFormatText
							alternatives={randomizedAlternatives}
							answerColor={answerColor}
							altOnClick={altOnClick}
							exerciseAnswerFormat={exercise.answerFormat!}
						/>
					</Row>
					:<></>
				}
				{ currentQuestion?.answerFormat === 'yes/no' ?
					<Row className="exercise--footer-fixed justify-content-center align-items-center">
						<AnswerFormatYesNo
							alternatives={randomizedAlternatives}
							answerColor={answerColor}
							altOnClick={altOnClick}
						/>
					</Row>
					:<></>
				}
			</>
		)
	}

	const exerciseFooter = () => {
		if (stats.status === 'next') {
			const isLast = stats.index + 1 === exerciseLength
			return (
				<>
					<StatusNext
						handleQuestion={handleQuestion}
						isLast={isLast}
					/>
				</>
			)
		} else if (stats.status === 'description') {
			return <StatusDescription />
		} else {
			return answerSelection()
		}
	}

	return (
		<Container fluid className='exercise--container h-100 min-vh-100'>
			{ exerciseLoading || alternativesLoading
				? <Loading />
				: renderPage()
			}
		</Container>
	)
}

export default ComprehensionExercisePage